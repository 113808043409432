import getInTouch from "../../assets/images/get-in-touch.svg";
const GetInTouchPage = () => {
  return (
    <div id="rcr-logo">
      <img src={getInTouch} alt="Race Car Rental" height="1400" width="1200" />
    </div>
  );
};

export default GetInTouchPage;
