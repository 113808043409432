import Logo from "../../../assets/images/logo.png";
import { Link } from "react-router-dom";

const Branding = () => {
  return (
    <Link to="/">
      <img src={Logo} alt="Branding" className="w-36 sm:w-56" />
    </Link>
  );
};

export default Branding;
