import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import api from "../../service/api";
import Loader from "../layout/blocks/Loader";
import Helmet from "react-helmet";
import Transitions from "../animations/Transitions";
import Breadcrumb from "../layout/blocks/Breadcrumb";

//icons
import facebook from "../../../src/assets/images/facebook.svg";
import instagram from "../../../src/assets/images/instagram.svg";

//captcha
import ReCAPTCHA from "react-google-recaptcha";

const Contact = () => {
  const [page, setPage] = useState([]);
  const [sendContactSuccess, setSendContactSuccess] = useState(false);
  const [sendContactError, setSendContactError] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [sendContactSuccessMessage, setSendContactSuccessMessage] =
    useState("");
  const [sendContactErrorMessage, setSendContactErrorMessage] = useState("");
  const [captchaValidated, setCaptchaValidated] = useState(false);
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const validate = (e) => {
    e.preventDefault();
    setSendContactError(false);
    setSendContactSuccess(false);
    let withErrors = false;
    let contactForm = document.getElementById("contact-form");
    //validate if all the fields are completed
    if (
      contactForm
        .querySelector("div#field-contact-full-name")
        .querySelector("input").value === "" ||
      contactForm
        .querySelector("div#field-contact-email")
        .querySelector("input").value === "" ||
      contactForm
        .querySelector("div#field-contact-subject")
        .querySelector("input").value === "" ||
      contactForm
        .querySelector("div#field-contact-phone")
        .querySelector("input").value === "" ||
      contactForm
        .querySelector("div#field-contact-message")
        .querySelector("textarea").value === ""
    ) {
      activateErrorFields();
      withErrors = true;
    }

    if (!captchaValidated) {
      let captcha_field = document.getElementById("field-contact-captcha");
      captcha_field.querySelector("small.error").classList.remove("hidden");
      withErrors = true;
    }

    if (!withErrors) {
      sendContact();
    }
  };
  const sendContact = () => {
    let contactForm = document.getElementById("contact-form");
    const postData = new FormData();
    postData.append(
      "full_name",
      contactForm
        .querySelector("div#field-contact-full-name")
        .querySelector("input").value
    );

    postData.append(
      "email",
      contactForm
        .querySelector("div#field-contact-email")
        .querySelector("input").value
    );

    postData.append(
      "subject",
      contactForm
        .querySelector("div#field-contact-subject")
        .querySelector("input").value
    );

    postData.append(
      "phone",
      contactForm
        .querySelector("div#field-contact-phone")
        .querySelector("input").value
    );

    postData.append(
      "message",
      contactForm
        .querySelector("div#field-contact-message")
        .querySelector("textarea").value
    );
    setShowLoader(true);
    api
      .post(process.env.REACT_APP_REST_API_BACKOFFICE + "contact", postData)
      .then((res) => {
        if (res.status === 200) {
          setSendContactSuccess(true);
          setSendContactSuccessMessage(
            "Thank you for getting in touch! We will be comunicate with you shortly"
          );
          setSendContactError(false);
          cleanForm();
        }
        setShowLoader(false);
      })
      .catch((err) => {
        setSendContactError(true);
        let message = "";
        try {
          let objMessage = JSON.parse(err.response.data.message);
          if (Object.keys(objMessage).length > 0) {
            if (objMessage.hasOwnProperty("email")) {
              objMessage.email.forEach((item) => {
                message = item;
              });
            } else {
              message = "There was an error with your submition!";
            }
          } else {
            message = err.response.data.message;
          }
        } catch {
          message = err.response.data.message;
        }
        setSendContactErrorMessage(message);
        setShowLoader(false);
      });
  };
  const validateField = (field_name, value) => {
    let field = document.getElementById("field-contact-" + field_name);
    if (value !== "") {
      field.classList.remove("error");
      field.querySelector("small.error").classList.add("hidden");
    }
  };
  const activateErrorFields = () => {
    let contactForm = document.getElementById("contact-form");
    if (
      contactForm
        .querySelector("div#field-contact-full-name")
        .querySelector("input").value === ""
    )
      activateErrorField("field-contact-full-name");
    if (
      contactForm
        .querySelector("div#field-contact-email")
        .querySelector("input").value === ""
    )
      activateErrorField("field-contact-email");
    if (
      contactForm
        .querySelector("div#field-contact-subject")
        .querySelector("input").value === ""
    )
      activateErrorField("field-contact-subject");
    if (
      contactForm
        .querySelector("div#field-contact-phone")
        .querySelector("input").value === ""
    )
      activateErrorField("field-contact-phone");
    if (
      contactForm
        .querySelector("div#field-contact-message")
        .querySelector("textarea").value === ""
    )
      activateErrorField("field-contact-message");
  };
  const activateErrorField = (id) => {
    const field = document.getElementById(id);
    field.querySelector("small.error").classList.remove("hidden");
    field.classList.add("error");
  };
  const cleanForm = () => {
    let contactForm = document.getElementById("contact-form");
    contactForm
      .querySelector("div#field-contact-full-name")
      .querySelector("input").value = "";
    contactForm
      .querySelector("div#field-contact-email")
      .querySelector("input").value = "";
    contactForm
      .querySelector("div#field-contact-subject")
      .querySelector("input").value = "";
    contactForm
      .querySelector("div#field-contact-phone")
      .querySelector("input").value = "";
    contactForm
      .querySelector("div#field-contact-message")
      .querySelector("textarea").value = "";
  };
  const validateCaptcha = (value) => {
    let captcha_field = document.getElementById("field-contact-captcha");
    if (value !== false) {
      setCaptchaValidated(true);
      captcha_field.querySelector("small.error").classList.add("hidden");
    } else {
      captcha_field.querySelector("small.error").innerHTML =
        "There was an errory validating the captcha, please try again";
      captcha_field.querySelector("small.error").classList.remove("hidden");
    }
  };
  useEffect(() => {
    let checkout_block = document.getElementById("checkout-reservations-block");
    checkout_block.classList.add("hidden");
    api.get("/page/" + process.env.REACT_APP_PAGE_CONTACT_US_ID).then((res) => {
      setPage(res.data.data);
    });
  }, []);
  return (
    <>
      <Transitions>
        <Helmet>
          <link
            rel="canonical"
            href={process.env.REACT_APP_DOMAIN + "/contact"}
          />
          <title>{process.env.REACT_APP_NAME + " | " + metaTitle}</title>
          <meta name="description" content={metaDescription} />
          <meta name="og:title" content={metaTitle} />
          <meta name="og:description" content={metaDescription} />
        </Helmet>
        <img
          rel="banner"
          src={process.env.REACT_APP_CMS_DOMAIN + "/" + page.banner}
          alt="banner"
        />
        <section className="content max-w-xl mx-auto overflow-hidden md:max-w-2xl lg:max-w-4xl xl:max-w-6xl">
          {showLoader && <Loader />}
          <Breadcrumb />
          <h2 className="text-red-600 text-2xl text-center mb-4">
            <span className="text-black text-lg">How</span> <br />
            Contact Us
          </h2>
          <div className="w-full mb-4 flex flex-wrap justify-between p-2">
            <div className="w-full md:w-7/12 mb-4" id="contact-form">
              <h2 className="text-black text-xl mb-1">Get In Touch</h2>
              <form onSubmit={(e) => validate(e)}>
                <div className="messages my-2">
                  {sendContactSuccess && (
                    <p className="notice py-4 px-2 text-sm success rounded-md">
                      {sendContactSuccessMessage}
                    </p>
                  )}
                  {sendContactError && (
                    <p className="notice p-2 text-sm error rounded-md">
                      {sendContactErrorMessage}
                    </p>
                  )}
                </div>
                <p>
                  Required fields <span>*</span>
                </p>
                <div className="flex flex-wrap justify-between text-sm font-light">
                  <div
                    className="field-content w-full md:w-[48%] py-2 px-0"
                    id="field-contact-full-name"
                  >
                    <input
                      type="text"
                      name="name"
                      placeholder="Your Name *"
                      className="border-2 w-full h-10 focus:outline-none p-2"
                      onChange={(e) =>
                        validateField("full-name", e.target.value)
                      }
                    ></input>
                    <small className="hidden error">
                      This field is required
                    </small>
                  </div>
                  <div
                    className="field-content w-full md:w-[48%] py-2 px-0"
                    id="field-contact-email"
                  >
                    <input
                      type="email"
                      name="email"
                      placeholder="Email Address *"
                      className="border-2 w-full h-10 focus:outline-none p-2"
                      onChange={(e) => validateField("email", e.target.value)}
                    ></input>
                    <small className="hidden error">
                      This field is required
                    </small>
                  </div>
                  <div
                    className="field-content w-full md:w-[48%] py-2 px-0"
                    id="field-contact-subject"
                  >
                    <input
                      type="text"
                      name="subject"
                      placeholder="Subject *"
                      className="border-2 w-full h-10 focus:outline-none p-2"
                      onChange={(e) => validateField("subject", e.target.value)}
                    ></input>
                    <small className="hidden error">
                      This field is required
                    </small>
                  </div>
                  <div
                    className="field-content w-full md:w-[48%] py-2 px-0"
                    id="field-contact-phone"
                  >
                    <input
                      type="text"
                      name="phone"
                      placeholder="Phone number *"
                      className="border-2 w-full h-10 focus:outline-none p-2"
                      onChange={(e) => validateField("phone", e.target.value)}
                    ></input>
                    <small className="hidden error">
                      This field is required
                    </small>
                  </div>
                </div>
                <div
                  className="field-content w-full mt-2 mb-4"
                  id="field-contact-message"
                >
                  <textarea
                    cols="8"
                    rows="6"
                    placeholder="Wrhite your message here...*"
                    className="w-full border-2 focus:outline-none p-2"
                    onChange={(e) => validateField("message", e.target.value)}
                  ></textarea>
                  <small className="hidden error">This field is required</small>
                </div>
                <div className="field-content" id="field-contact-captcha">
                  <ReCAPTCHA
                    id="contact-captcha"
                    sitekey="6LeXmlMgAAAAAFDoMN_674t57nfwtAz2dEyWQdkP"
                    onChange={validateCaptcha}
                  />
                  <small className="hidden error">This field is required</small>
                </div>
                <input
                  className="border-2 border-red-600 text-red-600 py-2 px-4 mt-4 cursor-pointer"
                  type="submit"
                  value="SEND MESSAGE"
                />
              </form>
            </div>
            <div className="w-full md:w-5/12 pt-0 pr-0 sm:pl-2 lg:pl-10 lg:pr-8">
              <h2 className="text-black text-xl mb-4">Contact Information</h2>
              <table>
                <tr>
                  <td className="pr-2 text-sm align-top">
                    <strong>Address: </strong>
                  </td>
                  <td>
                    <p className="contact-address text-sm lg:text-[1.2rem] w-[180px] md:w-[90%] lg:w-[80%] xl:w-[80%] leading-6">
                      {process.env.REACT_APP_CONTACT_ADDRESS}
                    </p>
                  </td>
                </tr>
              </table>
              <br />
              <div className="contact-buttons flex flex-wrap">
                <div className="w-full mb-6">
                  <p className="text-sm mb-3">Email</p>
                  <a
                    className="bg-red-600 text-white text-sm p-2 rounded-sm"
                    href={`mailto:${process.env.REACT_APP_CONTACT_EMAIL}`}
                  >
                    {process.env.REACT_APP_CONTACT_EMAIL}
                  </a>
                </div>
                <div className="w-full mb-6">
                  <p className="text-sm mb-3">Contact Us</p>
                  <Link
                    className="bg-red-600 text-white text-sm p-2 rounded-sm"
                    to=""
                  >
                    {process.env.REACT_APP_CONTACT_PHONE}
                  </Link>
                </div>
                {/* whatsapp */}
                <div className="w-full mb-6">
                  <p className="text-sm mb-3">Whatsapp</p>
                  <Link
                    className="bg-red-600 text-white text-sm p-2 rounded-sm"
                    to=""
                  >
                    {process.env.REACT_APP_WHATSAPP}
                  </Link>
                </div>
                <ul id="contact-social-media" className="flex flex-wrap mt-4">
                  <h3 className="w-full text-sm mb-1">Follow Us:</h3>
                  <li className="border border-neutral-200 rounded-lg p-1 w-[30px] mr-1">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={process.env.REACT_APP_FACEBOOK_LINK}
                    >
                      <img src={facebook} alt="facebook link" />
                    </a>
                  </li>
                  <li className="border border-neutral-200 rounded-lg p-1 w-[30px] mr-1">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={process.env.REACT_APP_INSTAGRAM_LINK}
                    >
                      <img src={instagram} alt="instagram link" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </Transitions>
    </>
  );
};

export default Contact;
