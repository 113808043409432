import { useState, useEffect, useContext } from "react";
import api from "../../service/race_api";
import AdditionalCharges from "../layout/blocks/AdditionalCharges";
import { useParams } from "react-router-dom";
import { FilterContext } from "../../components/context/MainProviders.js";
import Loader from "../layout/blocks/Loader";
import Moment from "moment";
import { FaExclamationTriangle } from "react-icons/fa";
import Helmet from "react-helmet";
import Transitions from "../animations/Transitions";
import Breadcrumb from "../layout/blocks/Breadcrumb";

const Car = () => {
  const filter = useContext(FilterContext);
  const [car, setCar] = useState({});
  const [features, setFeatures] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [carInfoLoaded, setCarInfoLoaded] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const { carId } = useParams();
  const initCarInformation = () => {
    setShowLoader(true);
    api
      .get(process.env.REACT_APP_API_FLEETS_VEHICLE.replace("[id]", carId))
      .then((res) => {
        console.log(res.data.data);
        setCar(res.data.data);
        setMetaTitle(res.data.data.label);
        let car_rate =
          res.data.data.vehicle_class.active_rates.length > 0
            ? res.data.data.vehicle_class.active_rates[0].daily_rate
            : 0;
        localStorage.setItem("car_price", car_rate);
        filter.ChangeClassId(res.data.data.vehicle_class_id);
        filter.ChangeBrandId(res.data.data.vehicle_class.brand_id);
        setCarInfoLoaded(true);
        validateCarAvailability(carId);
        const id_class = res.data.data.vehicle_class.id;
        api
          .get(
            process.env.REACT_APP_API_VEHICLE_CLASS.replace("[id]", id_class)
          )
          .then((res) => {
            setFeatures(res.data.fleets_vehicle_class.features);
            setShowLoader(false);
          });
      });
  };
  const validateCarAvailability = (id_car) => {
    api
      .get(
        process.env.REACT_APP_API_FLEETS_VEHICLE_BLOCKED_PERIODS.replace(
          "[id]",
          id_car
        )
      )
      .then((res) => {
        let isBlocked = false;
        //setFeatures(res.data.data.blocked_period.vehicle.features);

        res.data.blocked_periods.forEach((item) => {
          let start_date = Moment(item.start_date).format("YYYY/MM/DD");
          let end_date = Moment(item.end_date).format("YYYY/MM/DD");
          //validate the blocked period
          let datePickup = Moment(filter.datePickup).format("YYYY/MM/DD");
          let dateReturn = Moment(filter.dateReturn).format("YYYY/MM/DD");
          if (
            Moment(datePickup).isSameOrAfter(start_date) &&
            Moment(datePickup).isSameOrBefore(end_date)
          ) {
            isBlocked = true;
          }
        });
        setIsBlocked(isBlocked);

        setShowLoader(false);
      });
  };
  useEffect(() => {
    setCarInfoLoaded(false);
    initCarInformation();
    let checkout_block = document.getElementById("checkout-reservations-block");
    checkout_block.classList.add("hidden");
    //set car id localstorage
    localStorage.setItem("car_id", carId);
  }, []);
  return (
    <>
      <Transitions>
        <Helmet>
          <link
            rel="canonical"
            href={process.env.REACT_APP_DOMAIN + "/car/" + carId}
          />
          <title>{process.env.REACT_APP_NAME + " | " + metaTitle}</title>
          <meta name="description" content={metaDescription} />
          <meta name="og:title" content={metaTitle} />
          <meta name="og:description" content={metaDescription} />
        </Helmet>
        <section className="content max-w-xl mx-auto overflow-hidden md:max-w-2xl lg:max-w-4xl xl:max-w-6xl p-4">
          {showLoader && <Loader />}
          <Breadcrumb title={car.label} />
          <h2 className="text-red-600 text-2xl text-center mb-4">
            <span className="text-black text-lg">Our</span> <br />
            Cars
          </h2>
          <div
            id="car-header"
            className="bg-neutral-100 flex flex-col lg:flex-row"
          >
            <div className="w-full lg:w-2/4">
              <img
                src={
                  Object.keys(car).length !== 0
                    ? car.vehicle_class.public_image_link
                    : ""
                }
                alt=""
                className="w-full"
              />
            </div>
            <div className="w-full lg:w-2/4 p-4">
              {isBlocked ? (
                <button className="bg-red-600 text-white py-2 px-2 rounded flex flex-wrapper">
                  <FaExclamationTriangle color="yellow" className="mt-1 mr-1" />
                  Not available
                </button>
              ) : (
                <button className="bg-red-600 text-white p-2 py-0 rounded">
                  Rental
                </button>
              )}
              <h1 className="text-2xl my-2">
                {Object.keys(car).length !== 0 ? car.label : ""}
              </h1>
              <p className="text-left text-sm mb-2 font-bold">
                {process.env.REACT_APP_CURRENCY}
                {Object.keys(car).length !== 0
                  ? car.vehicle_class.active_rates.length > 0
                    ? Math.floor(car.vehicle_class.active_rates[0].daily_rate)
                    : 0
                  : 0}
                <span className="text-red-600">/Day</span>{" "}
              </p>
              <p
                id="car-description"
                className="text-xs font-light text-neutral-500 leading-5"
              >
                {Object.keys(car).length !== 0
                  ? car.vehicle_class.description_for_website.en
                  : ""}
              </p>
              {features.length > 0 ? (
                <ul className="flex flex-wrap p-0 list-none mt-4 text-xs">
                  {features.map((feature) => (
                    <li
                      key={feature.id}
                      className="w-full xs:w-2/4 sm:w-1/2 text-neutral-400 font-bold py-1 flex flex-wrap"
                    >
                      <p>{feature.label}</p>
                    </li>
                  ))}
                </ul>
              ) : null}
            </div>
          </div>
          {carInfoLoaded ? (
            <AdditionalCharges
              isBlocked={isBlocked}
              initCarInformation={initCarInformation}
            />
          ) : null}
        </section>
      </Transitions>
    </>
  );
};

export default Car;
