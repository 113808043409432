import { Link } from "react-router-dom";
import paypal from "../../assets/images/peach-logo.jpg";
const Footer = () => {
  return (
    <footer>
      <div className="footer-main bg-red-600 py-4 px-2 text-white relative overflow-hidden">
        <ul className="bars w-18 h-[200px] flex flex-wrap absolute left-[40%] md:left-[60%] -top-10">
          <li className="white w-2 h-full bg-white mx-[8px] rotate-[135deg]"></li>
          <li className="white w-2 h-full bg-white mx-[8px] rotate-[135deg]"></li>
          <li className="white w-2 h-full bg-white mx-[8px] rotate-[135deg]"></li>
        </ul>
        <div className="footer-wrapper max-w-xl mx-auto overflow-hidden md:max-w-2xl lg:max-w-4xl xl:max-w-6xl flex justify-between pl-8">
          <Link to="#" className="font-bold text-lg py-4 px-0">
            Book Now
          </Link>
          <span>
            <img src={paypal} alt="paypal" />
          </span>
        </div>
      </div>
      <div className="footer-bootom py-2 px-2">
        <div className="footer-bootom-wrapper max-w-xl mx-auto overflow-hidden md:max-w-2xl lg:max-w-4xl xl:max-w-6xl justify-between footer-bootom md:flex text-xs">
          <div className="left flex flex-wrap py-2">
            <p className="mr-2">
              {"\u00A9" +
                new Date().getFullYear() +
                " " +
                process.env.REACT_APP_PROJECT_NAME +
                " All Rights Reserved"}
            </p>
            <ul className="flex">
              <li className="border-r-2 border-gray-600 pr-2 mr-2">
                <Link className="hover:underline" to="/terms-and-conditions">
                  Terms & Conditions
                </Link>
              </li>
              <li className="border-r-2 border-gray-600 pr-2 mr-2">
                <Link className="hover:underline" to="/payment-and-booking">
                  Payment & Booking
                </Link>
              </li>
              <li>
                <Link className="hover:underline" to="/privacy-policy">
                  Privacy Policy
                </Link>
              </li>
            </ul>
          </div>
          <a
            className="right text-right w-[300px] hover:underline py-2"
            href="https://proximaweb.mu"
            target="_blank"
            rel="noreferrer"
          >
            Website by Proximaweb
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
