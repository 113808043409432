import { useState, useEffect } from "react";
import api from "../../../service/api";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
const Testimonials = () => {
  const [testimonials, setTestimonials] = useState([]);
  const [unknownError, setUnknownError] = useState(false);
  const getTestimonials = async () => {
    try {
      const result = await api.get(
        `${process.env.REACT_APP_REST_API_BACKOFFICE}testimonials`
      );
      setTestimonials(result.data.data);
    } catch {
      setUnknownError(true);
    }
  };
  useEffect(() => {
    getTestimonials();
  }, []);
  return (
    <div id="testimonials" className="px-10 pb-10 pt-4 mb-4">
      {unknownError ? (
        <p className="alert alert-danger">
          There was an error to connect with the server, pleade try again later
        </p>
      ) : (
        testimonials.length > 0 && (
          <Carousel
            autoPlay={true}
            infiniteLoop={true}
            showStatus={false}
            showThumbs={false}
            showIndicators={false}
          >
            {testimonials.map((testimonial, index) => (
              <div
                className="testimonial lg:px-16 px-4"
                key={`testimonial-${index}`}
              >
                <h3 className="mb-2">{testimonial.title}</h3>
                <div
                  dangerouslySetInnerHTML={{ __html: testimonial.content }}
                ></div>
              </div>
            ))}
          </Carousel>
        )
      )}
    </div>
  );
};

export default Testimonials;
