import { useState, useEffect, useContext } from "react";
import FilterCars from "../layout/blocks/FilterCars";
import FilterBrands from "../layout/blocks/FilterBrands";
import CarList from "../layout/blocks/CarList";
import { CarListContext, FilterContext } from "../context/MainProviders.js";
import Helmet from "react-helmet";
import api from "../../service/race_api";
import Breadcrumb from "../layout/blocks/Breadcrumb";
import Transitions from "../animations/Transitions";
import Moment from "moment";

const Cars = () => {
  const carList = useContext(CarListContext);
  const filter = useContext(FilterContext);
  const [availableCarList, setAvailableCarList] = useState([]);
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const getFleet = async () => {
    const response = await api.get(process.env.REACT_APP_API_FLEETS_VEHICLES);
    carList.updateCarList(response.data.data);
    let availableCarListTmp = [];
    response.data.data.forEach((car) => {
      if (ValidateCarAvailableList(car)) availableCarListTmp.push(car);
    });
    setAvailableCarList(availableCarListTmp);
  };
  const ValidateCarAvailableList = async (car) => {
    const result = await api.get(
      process.env.REACT_APP_API_FLEETS_VEHICLE_BLOCKED_PERIODS.replace(
        "[id]",
        car.id
      )
    );
    let isBlocked = false;
    result.data.blocked_periods.forEach((item) => {
      let start_date = Moment(item.start_date).format("YYYY/MM/DD");
      let end_date = Moment(item.end_date).format("YYYY/MM/DD");
      //validate the blocked period
      let datePickup = Moment(filter.datePickup).format("YYYY/MM/DD");
      if (
        Moment(datePickup).isSameOrAfter(start_date) &&
        Moment(datePickup).isSameOrBefore(end_date)
      ) {
        isBlocked = true;
      }
    });
    return isBlocked;
  };
  useEffect(() => {
    let checkout_block = document.getElementById("checkout-reservations-block");
    checkout_block.classList.add("hidden");
    getFleet();
  }, []);
  return (
    <>
      <Transitions>
        <Helmet>
          <link rel="canonical" href={process.env.REACT_APP_DOMAIN + "/cars"} />
          <title>{process.env.REACT_APP_NAME + " | " + metaTitle}</title>
          <meta name="description" content={metaDescription} />
          <meta name="og:title" content={metaTitle} />
          <meta name="og:description" content={metaDescription} />
        </Helmet>
        <section className="content max-w-xl mx-auto overflow-hidden md:max-w-2xl lg:max-w-4xl xl:max-w-6xl">
          <Breadcrumb />
          <h2 className="text-red-600 text-2xl text-center mb-4 w-full">
            <span className="text-black text-lg">Our</span> <br />
            Cars
          </h2>
          <div className="inner-content flex flex-wrap">
            <div className="sidebar-left w-full lg:w-1/4 p-4">
              <FilterCars />
              <FilterBrands availableCarList={availableCarList} />
            </div>
            <div className="main-content w-full lg:w-3/4">
              <CarList availableCarList={availableCarList} />
            </div>
          </div>
        </section>
      </Transitions>
    </>
  );
};

export default Cars;
