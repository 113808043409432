import { Link } from "react-router-dom";
import User from "./blocks/User";
import Branding from "./blocks/Branding";
import CallIcon from "../../assets/images/call-icon.png";
import SocialSchedule from "./blocks/SocialSchedule";

const TopHeader = () => {
  return (
    <header>
      <div className="top bg-rcr_gray-600">
        <div className="h-10 p-1 text-white max-w-xl mx-auto overflow-hidden md:max-w-2xl lg:max-w-4xl xl:max-w-6xl flex text-xs md:text-base">
          <div className="h-0 w-[70%] md:w-[57%] absolute left-0 top-0 border-b-[55px] border-b-red-600 border-r-[80px] border-r-transparent"></div>
          <ul className="contact flex w-2/4 md:w-2/4 z-20">
            <li className="xl:pl-16">
              <Link
                to="/need-help"
                className="px-0 py-1 sm:py-2 inline-block font-bold"
              >
                Need Help?
              </Link>
            </li>
            <li>
              <img
                src={CallIcon}
                alt="call-icon"
                className="px-2 max-w-[45px] max-h-[25px] mt-2 md:max-w-[55px] md:max-h-[30px] md:mt-1"
              />
            </li>
            <li>
              <p className="px-1 py-1 sm:py-2 font-bold">
                Call: {process.env.REACT_APP_PHONE}
              </p>
            </li>
          </ul>
          <div className="w-2/4 md:w-2/4">
            <User />
          </div>
        </div>
      </div>
      <div className="main-header py-6 px-0">
        <div className="max-w-xl mx-auto bg-white overflow-hidden md:max-w-2xl lg:max-w-4xl xl:max-w-6xl flex justify-between">
          <div className="w-2/4 sm:w-1/4 lg:w-2/4">
            <Branding />
          </div>
          <div className="w-2/4 sm:w-3/4 md:w-3/4 lg:w-2/4">
            <SocialSchedule />
          </div>
        </div>
      </div>
    </header>
  );
};

export default TopHeader;
