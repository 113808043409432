import { useState, useContext } from "react";
import { Link } from "react-router-dom";
import cartImage from "../../assets/images/caddy.png";
import facebook from "../../assets/images/facebook.png";
import linkedin from "../../assets/images/linkedin.png";
import instagram from "../../assets/images/instagram.png";
import close from "../../assets/images/close.svg";
import { CartContext } from "../context/MainProviders.js";

const Menu = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const cart = useContext(CartContext);
  return (
    <section className="navigation bg-rcr_gray-600">
      <div className="navigation-wrapper flex py-4 max-w-xl mx-auto overflow-hidden md:max-w-2xl lg:max-w-4xl xl:max-w-6xl">
        <nav
          className="font-light hidden lg:inline-block w-5/6 text-white"
          id="main-menu"
        >
          <Link
            to="/"
            className="py-1 px-4 border-r hover:text-neutral-600 hover:bg-white"
          >
            Home
          </Link>
          <Link
            to="/about-us"
            className="py-1 px-4 border-r hover:text-neutral-600 hover:bg-white"
          >
            About us
          </Link>
          <Link
            to="/how-it-works"
            className="py-1 px-4 border-r hover:text-neutral-600 hover:bg-white"
          >
            How it works
          </Link>
          <Link
            to="/faq"
            className="py-1 px-4 border-r hover:text-neutral-600 hover:bg-white"
          >
            FAQ
          </Link>
          <Link
            to="/cars"
            className="py-1 px-4 border-r hover:text-neutral-600 hover:bg-white"
          >
            Cars
          </Link>
          <Link
            to="/services"
            className="py-1 px-4 border-r hover:text-neutral-600 hover:bg-white"
          >
            Services
          </Link>
          <Link
            to="/news"
            className="py-1 px-4 border-r hover:text-neutral-600 hover:bg-white"
          >
            News
          </Link>
          <Link
            to="/contact"
            className="py-1 px-4 hover:text-neutral-600 hover:bg-white"
          >
            Contact
          </Link>
        </nav>
        {!menuOpen ? (
          <button
            className="menu-respnsive-toggle lg:hidden ml-2"
            id="menu-responsive-toggle"
            onClick={() => setMenuOpen(!menuOpen)}
          >
            <svg fill="white" viewBox="0 0 100 80" width="30" height="30">
              <rect width="100" height="15"></rect>
              <rect y="30" width="100" height="15"></rect>
              <rect y="60" width="100" height="15"></rect>
            </svg>
          </button>
        ) : (
          <div>
            <button
              className="menu-respnsive-toggle lg:hidden ml-2"
              id="menu-responsive-toggle"
              onClick={() => setMenuOpen(!menuOpen)}
            >
              <img className="w-[30px]" src={close} id="close-btn" />
            </button>
            <div id="menu-overlay" onClick={() => setMenuOpen(!menuOpen)}></div>
          </div>
        )}
        <div
          className={`lg:hidden h-full w-4/5 fixed z-3 bg-white top-0 right-0 z-10 ${
            menuOpen ? "translate-x-0" : "translate-x-full"
          } ease-in-out duration-150`}
        >
          <button
            className="text-lg bg-neutral-500 text-white rounded-2xl absolute py-0 px-2 top-2 right-2"
            onClick={() => setMenuOpen(!menuOpen)}
          >
            X
          </button>
          <ul id="responsive-menu" className="mt-6">
            <li className="flex-1 w-full border-0 py-4 pl-8">
              <Link
                to="/"
                className="text-md text-light text-neutral-700 inline-block w-full"
              >
                Home
              </Link>
            </li>
            <li className="flex-1 w-full border-0 py-4 pl-8">
              <Link
                to="/about-us"
                className="text-md text-light text-neutral-700 inline-block w-full"
              >
                About us
              </Link>
            </li>
            <li className="flex-1 w-full border-0 py-4 pl-8">
              <Link
                to="/how-it-works"
                className="text-md text-light text-neutral-700 inline-block w-full"
              >
                How it works
              </Link>
            </li>
            <li className="flex-1 w-full border-0 py-4 pl-8">
              <Link
                to="/faq"
                className="text-md text-light text-neutral-700 inline-block w-full"
              >
                FAQ
              </Link>
            </li>
            <li className="flex-1 w-full border-0 py-4 pl-8">
              <Link
                to="/cars"
                className="text-md text-light text-neutral-700 inline-block w-full"
              >
                Cars
              </Link>
            </li>
            <li className="flex-1 w-full border-0 py-4 pl-8">
              <Link
                to="/services"
                className="text-md text-light text-neutral-700 inline-block w-full"
              >
                Services
              </Link>
            </li>
            <li className="flex-1 w-full border-0 py-4 pl-8">
              <Link
                to="/news"
                className="text-md text-light text-neutral-700 inline-block w-full"
              >
                News
              </Link>
            </li>
            <li className="flex-1 w-full border-0 py-4 pl-8">
              <Link
                to="/contact"
                className="text-md text-light text-neutral-700 inline-block w-full"
              >
                Contact
              </Link>
            </li>
          </ul>
          <div className="social justify-left sm:w-2/4 md:w-1/4 sm:flex p-6">
            <ul className="flex justify-items-stretch">
              <li className="p-1">
                <Link to="#">
                  <img src={facebook} alt="facebook" />
                </Link>
              </li>
              <li className="p-1">
                <Link to="#">
                  <img src={linkedin} alt="facebook" />
                </Link>
              </li>
              <li className="p-1">
                <Link to="#">
                  <img src={instagram} alt="facebook" />
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <div className="cart w-full lg:w-1/6 flex justify-end">
          <Link
            to={!cart.isEmpty ? "/billing" : "/"}
            className="inline-block relative"
          >
            <img src={cartImage} alt="cart" />
            {!cart.isEmpty ? (
              <span className="bg-red-600 text-xs text-white font-bold px-[4.5px] py-0 -top-1 right-[0.5px] rounded-md absolute">
                1
              </span>
            ) : null}
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Menu;
