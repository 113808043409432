import React, { useState, useEffect } from "react";
import api from "../../../service/race_api";
import { Link } from "react-router-dom";
import Swiper, { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
const CarListHome = ({ availableCarList }) => {
  const [homeCarList, setHomeCarList] = useState([]);
  const generateClassList = () => {
    let classListTmp = [];
    availableCarList.forEach((car) => {
      if (!validateCarClassExist(classListTmp, car.vehicle_class.name)) {
        classListTmp.push(car);
      }
    });
    setHomeCarList(classListTmp);
  };
  const validateCarClassExist = (list, name) => {
    let found = false;
    list.forEach((carClass) => {
      if (carClass.vehicle_class.name === name) found = true;
    });
    return found;
  };
  const loadCarList = async () => {
    generateClassList();
  };
  //init Swiper:
  const swiper = new Swiper(".swiper", {
    direction: "horizontal",
    loop: true,
    speed: 400,
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
    },
  });
  useEffect(() => {
    loadCarList();
  }, [availableCarList]);
  return (
    <div className="car-list-home">
      {homeCarList.length > 0 && (
        <>
          <ul className="flex-wrap hidden md:flex">
            {homeCarList.map(
              (car, index) =>
                index < 6 && (
                  <li
                    key={car.id}
                    className="w-full md:w-[28.5%] xl:w-[30%] p-3 border-2 border-neutral-200 my-4 mx-4"
                  >
                    <button>
                      {
                        <Link to={"/cars/" + car.id}>
                          <img
                            src={car.vehicle_class.public_image_link + "/"}
                            alt=""
                            className="w-full"
                          />
                        </Link>
                      }
                    </button>
                    <h3 className="text-center text-lg font-bold py-2 px-0">
                      <Link to={"/cars/" + car.id}>
                        {car.vehicle_class.name}
                      </Link>
                    </h3>
                    <ul className="actions mt-4 px-0 flex w-full">
                      <li className="w-full sm:w-2/4 text-white text-lg">
                        <Link
                          className="inline-block w-full text-center text-xs font-bold uppercase py-2 bg-black"
                          to={"/cars/" + car.id}
                        >
                          Rent Car
                        </Link>
                      </li>
                      <li className="w-full sm:w-2/4 text-white text-lg">
                        <Link
                          className="inline-block w-full text-center text-xs font-bold uppercase py-2 bg-red-600"
                          to={"/cars/" + car.id}
                        >
                          Details
                        </Link>
                      </li>
                    </ul>
                  </li>
                )
            )}
          </ul>
          <div className="md:hidden swiper">
            <div className="swiper-wrapper">
              {homeCarList.map(
                (car, index) =>
                  index < 6 && (
                    <div key={car.id} className="swiper-slide">
                      <button>
                        {
                          <Link to={"/cars/" + car.id}>
                            <img
                              src={car.vehicle_class.public_image_link + "/"}
                              alt=""
                              className="w-full"
                            />
                          </Link>
                        }
                      </button>
                      <h3 className="text-center text-lg font-bold py-2 px-0">
                        <Link to={"/cars/" + car.id}>
                          {car.vehicle_class.name}
                        </Link>
                      </h3>
                      {/* <ul className="flex flex-wrap w-full lg:w-5/6 my-0 mx-auto text-xs justify-around text-neutral-400">
                      <li className="my-0 mx-3 text-center">{car.plate}</li>
                    </ul> */}
                      <ul className="actions mt-4 px-0 flex w-full">
                        <li className="w-full sm:w-2/4 text-white text-lg">
                          <Link
                            className="inline-block w-full text-center text-xs font-bold uppercase py-2 bg-black"
                            to={"/cars/" + car.id}
                          >
                            Rent Car
                          </Link>
                        </li>
                        <li className="w-full sm:w-2/4 text-white text-lg">
                          <Link
                            className="inline-block w-full text-center text-xs font-bold uppercase py-2 bg-red-600"
                            to={"/cars/" + car.id}
                          >
                            Details
                          </Link>
                        </li>
                      </ul>
                    </div>
                  )
              )}
              <div className="swiper-pagination"></div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CarListHome;
