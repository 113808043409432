import { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import RentalSummary from "../layout/blocks/RentalSummary";
import DatePicker from "react-datepicker";
import { CartContext } from "../context/MainProviders.js";
import api from "../../service/api";
import Breadcrumb from "../layout/blocks/Breadcrumb";

const Billing = () => {
  const [carId, setCarId] = useState(0);
  const cart = useContext(CartContext);
  const navigate = useNavigate();
  const [birthday, setBirthday] = useState(
    localStorage.getItem("billing_address") !== null
      ? JSON.parse(localStorage.getItem("billing_address")).birthday !== ""
        ? new Date(
            Date.parse(
              JSON.parse(localStorage.getItem("billing_address")).birthday
            )
          )
        : new Date()
      : new Date()
  );
  const [expirationDate, setExpirationDate] = useState(
    localStorage.getItem("drivers_license") !== null
      ? JSON.parse(localStorage.getItem("drivers_license")).expiration_date !==
        ""
        ? new Date(
            Date.parse(
              JSON.parse(localStorage.getItem("drivers_license"))
                .expiration_date
            )
          )
        : new Date()
      : new Date()
  );
  const [billingAddress, setBillingAddress] = useState(
    localStorage.getItem("billing_address") !== null
      ? JSON.parse(localStorage.getItem("billing_address"))
      : {
          first_name: "",
          last_name: "",
          email: "",
          phone: "",
          city: "",
          address: "",
          zip_code: "",
          country: "MU",
          birthday: "",
          hotel_room: "",
          prev_email: "",
        }
  );
  const [driversLicense, setDriversLicense] = useState(
    localStorage.getItem("drivers_license") !== null
      ? JSON.parse(localStorage.getItem("drivers_license"))
      : {
          dl_number: "",
          expiration_date: "",
          id_file: "",
          file: "",
        }
  );
  const updateBilling = (name, value) => {
    let tmpBilling = billingAddress;
    let objBilling = {};

    tmpBilling[name] = value;
    Object.assign(objBilling, tmpBilling);
    setBillingAddress(objBilling);

    localStorage.setItem("billing_address", JSON.stringify(objBilling));

    //remove the error requirement if exists
    const field = document.getElementById("field-" + name.replace("_", "-"));
    if (field !== null) {
      field.querySelector("small.error").classList.add("hidden");
      field.classList.remove("error");
    }
  };
  const updateDriversLicense = (name, value) => {
    if (value !== undefined) {
      let tmpDriversLicense = driversLicense;
      if (name === "id_file") {
        if (
          tmpDriversLicense.id_file !== null &&
          tmpDriversLicense.id_file !== ""
        ) {
          //delete file
          api
            .delete(
              process.env.REACT_APP_REST_API_BACKOFFICE +
                "document/" +
                tmpDriversLicense.id_file
            )
            .then((res) => {
              if (res.status === 200) {
                uploadFile(name, value);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          uploadFile(name, value);
        }
      } else {
        let objDriversLicense = {};

        tmpDriversLicense[name] = value;
        Object.assign(objDriversLicense, tmpDriversLicense);
        setDriversLicense(objDriversLicense);

        localStorage.setItem(
          "drivers_license",
          JSON.stringify(objDriversLicense)
        );
      }
    }
  };
  const triggerFileUpload = () => {
    let fileUpload = document.querySelector("input#file-upload");
    fileUpload.click();
  };
  const closeFieldset = (id) => {
    let fieldset = document.getElementById(id);
    if (fieldset.classList.contains("hidden") === false) {
      fieldset.classList.add("hidden");
    } else {
      fieldset.classList.remove("hidden");
    }
  };
  const uploadFile = (name, value) => {
    let postData = new FormData();
    let tmpDriversLicense = driversLicense;
    let objDriversLicense = {};
    postData.append("document", value);
    api
      .post(process.env.REACT_APP_REST_API_BACKOFFICE + "document", postData)
      .then((res) => {
        if (res.status === 200) {
          tmpDriversLicense[name] = res.data.data.id;
          tmpDriversLicense["file"] = res.data.data.path;
          Object.assign(objDriversLicense, tmpDriversLicense);
          setDriversLicense(objDriversLicense);
          localStorage.setItem(
            "drivers_license",
            JSON.stringify(objDriversLicense)
          );
        }
      });
  };
  const cartContinue = () => {
    const summary = document.getElementById("rental-summary");
    summary.querySelector("div.summary-actions button.continue").click();
  };

  useEffect(() => {
    if (localStorage.getItem("cart") === null) {
      cart.init();
    }

    cart.changeCartStep("billing");
    cart.changeCartEmpty(false);
    setCarId(localStorage.getItem("car_id"));

    if (localStorage.getItem("billing_address") === null)
      localStorage.setItem("billing_address", JSON.stringify(billingAddress));

    if (localStorage.getItem("drivers_license") === null)
      localStorage.setItem("drivers_license", JSON.stringify(driversLicense));
  }, []);

  useEffect(() => {
    //update when tihe birthday has been selected
    updateBilling(
      "birthday",
      birthday.getFullYear() +
        "-" +
        (birthday.getMonth() + 1) +
        "-" +
        birthday.getDate()
    );

    //update when tihe expiration date has been selected
    updateDriversLicense(
      "expiration_date",
      expirationDate.getFullYear() +
        "-" +
        (expirationDate.getMonth() + 1) +
        "-" +
        expirationDate.getDate()
    );
  }, [birthday, expirationDate]);

  return (
    <section className="content max-w-xl mx-auto overflow-hidden md:max-w-2xl lg:max-w-4xl xl:max-w-6xl p-4">
      <Breadcrumb />
      <h2 className="text-red-600 text-2xl text-center mb-4">
        <span className="text-black text-lg">Our</span> <br />
        Cars
      </h2>
      <div id="billing" className="flex flex-col lg:flex-row">
        <div className="mb-2 sm:mb-10 w-full lg:w-3/4 flex flex-wrap lg:pr-6">
          <div>
            <span className="required">*</span> Required fields
          </div>
          <fieldset className="w-full">
            <legend
              className="w-full inline-block mb-3 p-2 border-b border-neutral-300"
              onChange={() =>
                closeFieldset("fielset-content-customer-information")
              }
            >
              <h2 className="text-md text-red-600 w-full">
                Customer Information
              </h2>
            </legend>
            <div
              id="fielset-content-customer-information"
              className="fielset-content w-full flex flex-wrap"
            >
              <div
                className="field w-full sm:w-2/4 p-2 flex flex-col mb-1"
                id="field-first-name"
              >
                <label htmlFor="first-name" className="w-full mb-1 text-xs">
                  First Name
                  <span className="required">*</span>
                </label>
                <input
                  type="text"
                  name="first_name"
                  className="border border-neutral-300 w-full focus:outline-none pl-2 text-xs py-1"
                  id="first-name"
                  onChange={(e) => updateBilling(e.target.name, e.target.value)}
                  value={billingAddress.first_name}
                />
                <small className="error hidden"></small>
              </div>
              <div
                className="field w-full sm:w-2/4 p-2 flex flex-col mb-1"
                id="field-last-name"
              >
                <label htmlFor="last-name" className="w-full mb-1 text-xs">
                  Last Name
                  <span className="required">*</span>
                </label>
                <input
                  type="text"
                  name="last_name"
                  className="border border-neutral-300 w-full focus:outline-none pl-2 text-xs py-1"
                  id="last-name"
                  onChange={(e) => updateBilling(e.target.name, e.target.value)}
                  value={billingAddress.last_name}
                />
                <small className="error hidden"></small>
              </div>
              <div
                className="field w-full sm:w-2/4 p-2 flex flex-col mb-1"
                id="field-email"
              >
                <label htmlFor="email" className="w-full mb-1 text-xs">
                  E-mail Address
                  <span className="required">*</span>
                </label>
                <input
                  type="email"
                  name="email"
                  className="border border-neutral-300 w-full focus:outline-none pl-2 text-xs py-1"
                  id="email"
                  onChange={(e) => updateBilling(e.target.name, e.target.value)}
                  value={billingAddress.email}
                />
                <small className="error hidden"></small>
              </div>
              <div
                className="field w-full sm:w-2/4 p-2 flex flex-col mb-1"
                id="field-phone"
              >
                <label htmlFor="phone" className="w-full mb-1 text-xs">
                  Phone Number
                  <span className="required">*</span>
                </label>
                <input
                  type="text"
                  name="phone"
                  className="border border-neutral-300 w-full focus:outline-none pl-2 text-xs py-1"
                  id="phone"
                  onChange={(e) => updateBilling(e.target.name, e.target.value)}
                  value={billingAddress.phone}
                />
                <small className="error hidden"></small>
              </div>
              <div
                className="field w-full sm:w-2/4 p-2 flex flex-col mb-1"
                id="field-city"
              >
                <label htmlFor="city" className="w-full mb-1 text-xs">
                  City
                  <span className="required">*</span>
                </label>
                <input
                  type="text"
                  name="city"
                  className="border border-neutral-300 w-full focus:outline-none pl-2 text-xs py-1"
                  id="city"
                  onChange={(e) => updateBilling(e.target.name, e.target.value)}
                  value={billingAddress.city}
                />
                <small className="error hidden"></small>
              </div>
              <div
                className="field w-full sm:w-2/4 p-2 flex flex-col mb-1"
                id="field-address"
              >
                <label htmlFor="address" className="w-full mb-1 text-xs">
                  Address
                  <span className="required">*</span>
                </label>
                <input
                  type="text"
                  name="address"
                  className="border border-neutral-300 w-full focus:outline-none pl-2 text-xs py-1"
                  id="address"
                  onChange={(e) => updateBilling(e.target.name, e.target.value)}
                  value={billingAddress.address}
                />
                <small className="error hidden"></small>
              </div>
              <div
                className="field w-full sm:w-2/4 p-2 flex flex-col mb-1"
                id="field-zip-code"
              >
                <label htmlFor="zip" className="w-full mb-1 text-xs">
                  Zip code
                </label>
                <input
                  type="text"
                  name="zip_code"
                  className="border border-neutral-300 w-full focus:outline-none pl-2 text-xs py-1"
                  id="zip-code"
                  onChange={(e) => updateBilling(e.target.name, e.target.value)}
                  value={billingAddress.zip_code}
                />
              </div>
              <div
                className="field w-full sm:w-2/4 p-2 flex flex-col mb-1"
                id="field-country"
              >
                <label htmlFor="country" className="w-full mb-1 text-xs">
                  Country
                </label>
                <select
                  id="countries-list"
                  name="country"
                  className="p-1 text-xs bg-white border border-neutral-300"
                  onChange={(e) => updateBilling(e.target.name, e.target.value)}
                  value={billingAddress.country}
                >
                  <small className="error hidden"></small>
                  <option value="MU">Mauritius</option>
                </select>
              </div>
              <div
                className="field w-full sm:w-2/4 p-2 flex flex-col mb-1"
                id="field-birthday"
              >
                <label htmlFor="birthday" className="w-full mb-1 text-xs">
                  Birthday
                  <span className="required">*</span>
                </label>
                <DatePicker
                  className="mt-2 w-full text-xs p-1 border border-neutral-300"
                  name="birthday"
                  selected={birthday}
                  onChange={(date) => setBirthday(date)}
                  dateFormat="dd/MM/yyyy"
                  showMonthDropdown
                  showYearDropdown
                />
                <small className="error hidden"></small>
              </div>
            </div>
          </fieldset>
          <fieldset className="w-full">
            <legend
              className="w-full inline-block mb-3 p-2 border-b border-neutral-300"
              onChange={(e) => closeFieldset("fielset-content-drivers-license")}
            >
              <h2 className="text-md text-red-600 w-full">Driver's License</h2>
            </legend>
            <div
              id="fielset-content-drivers-license"
              className="w-full fieldset-content flex flex-wrap"
            >
              <div className="field w-full sm:w-1/3 flex flex-col p-2">
                <label
                  htmlFor="dl-number"
                  className="w-full font-normal text-xs mb-2"
                >
                  DL Number
                </label>
                <input
                  type="text"
                  name="dl_number"
                  className="border border-natural-300 w-full text-xs py-1 pl-2 focus:outline-none"
                  id="dl-number"
                  onChange={(e) =>
                    updateDriversLicense(e.target.name, e.target.value)
                  }
                  value={driversLicense.dl_number}
                />
              </div>
              <div
                className="field w-full sm:w-1/3 flex flex-col p-2"
                id="field-expiration-date"
              >
                <label
                  htmlFor="dl-number"
                  className="w-full font-normal text-xs mb"
                >
                  Expiration Date
                </label>
                <DatePicker
                  className="mt-2 w-full text-xs p-1 border border-neutral-200"
                  selected={expirationDate}
                  onChange={(date) => setExpirationDate(date)}
                  dateFormat="dd/MM/yyyy"
                  showMonthDropdown
                  showYearDropdown
                  name="expiration_date"
                />
                <small className="error hidden"></small>
              </div>
              <div className="field w-full sm:w-1/3 flex flex-col p-2">
                <label
                  htmlFor="file-upload"
                  className="w-full font-normal text-xs mb-2"
                >
                  File Upload
                </label>
                <input
                  type="file"
                  id="file-upload"
                  className="hidden"
                  onChange={(e) =>
                    updateDriversLicense("id_file", e.target.files[0])
                  }
                />
                <div className="image-preview flex justify-between">
                  {Object.keys(driversLicense).length > 0 &&
                    driversLicense.file !== "" && (
                      <img
                        className="w-[100px] border border-neutral-300"
                        src={driversLicense.file}
                      />
                    )}
                  <button
                    className="h-[26px] border text-[10px] w-25 px-2 border-dotted border-neutral-400"
                    onClick={(e) => triggerFileUpload()}
                  >
                    Add image
                  </button>
                </div>
              </div>
            </div>
          </fieldset>
          <div className="actions flex justify-end w-full mt-4">
            <button
              className="text-white bg-neutral-500 border border-neutral-400 py-1 px-2 text-md mr-2"
              onClick={() => navigate("/cars/" + carId)}
            >
              Back
            </button>
            <button
              className="text-white bg-red-600 py-1 px-2 text-md"
              onClick={cartContinue}
            >
              Continue
            </button>
          </div>
        </div>
        {!cart.isEmpty ? <RentalSummary step="review" /> : null}
      </div>
    </section>
  );
};

export default Billing;
