import React, { useState, useEffect, useContext } from "react";
import VideoBanner from "../layout/blocks/VideoBanner";
import Helmet from "react-helmet";
import CarListHome from "../layout/blocks/CarListHome";
import SearchBlock from "../layout/blocks/SearchBlock";
import Testimonials from "../layout/blocks/Testimonials";
import Transitions from "../animations/Transitions";
import { CarListContext, FilterContext } from "../context/MainProviders.js";
import api from "../../service/race_api";
import backend_api from "../../service/api";
import Moment from "moment";
const Home = () => {
  const carList = useContext(CarListContext);
  const filter = useContext(FilterContext);
  const [page, setPage] = useState([]);
  const [metaTitle, setMetaTitle] = useState("Home");
  const [metaDescription, setMetaDescription] = useState("");
  const [availableCarList, setAvailableCarList] = useState([]);
  const getFleet = async () => {
    const response = await api.get(process.env.REACT_APP_API_FLEETS_VEHICLES);
    carList.updateCarList(response.data.data);
    let availableCarListTmp = [];
    response.data.data.forEach((car) => {
      if (ValidateCarAvailableList(car)) availableCarListTmp.push(car);
    });
    setAvailableCarList(availableCarListTmp);
  };
  const ValidateCarAvailableList = async (car) => {
    const result = await api.get(
      process.env.REACT_APP_API_FLEETS_VEHICLE_BLOCKED_PERIODS.replace(
        "[id]",
        car.id
      )
    );
    let isBlocked = false;
    result.data.blocked_periods.forEach((item) => {
      let start_date = Moment(item.start_date).format("YYYY/MM/DD");
      let end_date = Moment(item.end_date).format("YYYY/MM/DD");
      //validate the blocked period
      let datePickup = Moment(filter.datePickup).format("YYYY/MM/DD");
      if (
        Moment(datePickup).isSameOrAfter(start_date) &&
        Moment(datePickup).isSameOrBefore(end_date)
      ) {
        isBlocked = true;
      }
    });
    return isBlocked;
  };
  useEffect(() => {
    let checkout_block = document.getElementById("checkout-reservations-block");
    checkout_block.classList.add("hidden");
    backend_api.get("/page/" + process.env.REACT_APP_PAGE_HOME).then((res) => {
      setPage(res.data.data);
      //get meta
      const title = res.data.data.title;
      setMetaTitle(title);
      const description = res.data.data.description;
      setMetaDescription(description);
    });
    getFleet();
  }, []);
  return (
    <>
      <Transitions>
        <Helmet>
          <link rel="canonical" href={process.env.REACT_APP_DOMAIN + "/"} />
          <title>{process.env.REACT_APP_NAME + " | " + metaTitle}</title>
          <meta name="description" content={metaDescription} />
          <meta name="og:title" content={metaTitle} />
          <meta name="og:description" content={metaDescription} />
        </Helmet>
        <VideoBanner />
        <SearchBlock />
        <section className="content content-top max-w-xl mx-auto overflow-hidden md:max-w-2xl xl:max-w-6xl md:pt-32 pb-20 relative">
          <h2 className="text-red-600 text-2xl text-center mb-4">
            <span className="text-black text-lg">Browse through our</span>{" "}
            <br />
            Hot offers
          </h2>
          <CarListHome availableCarList={availableCarList} />
        </section>
        <div className="banner main-banner">
          <img
            rel="banner"
            src={process.env.REACT_APP_CMS_DOMAIN + "/" + page.banner}
          />
        </div>
        <section className="content content-middle content-top max-w-xl mx-auto overflow-hidden md:max-w-2xl xl:max-w-6xl md:pt-10 relative">
          <h2 className="text-red-600 text-2xl text-center mb-4">
            Why Hire a Car in Mauritius with Us?
          </h2>
          <div
            className="why-us text-neutral-600 text-xs p-2"
            dangerouslySetInnerHTML={{ __html: page.content }}
          />
        </section>
        <div className="banner red-banner relative bg-red-600 h-20 overflow-hidden">
          <ul className="bars w-18 h-[200px] flex flex-wrap absolute left-[20%] -top-10">
            <li className="white w-2 h-full bg-white mx-[8px] rotate-[135deg]"></li>
            <li className="white w-2 h-full bg-white mx-[8px] rotate-[135deg]"></li>
            <li className="white w-2 h-full bg-white mx-[8px] rotate-[135deg]"></li>
          </ul>
        </div>
        <section className="content content-middle content-top max-w-xl mx-auto overflow-hidden md:max-w-2xl xl:max-w-6xl md:pt-10 relative">
          <h2 className="text-red-600 text-2xl text-center mb-4">
            TESTIMONIALS
          </h2>
          <Testimonials />
        </section>
      </Transitions>
    </>
  );
};

export default Home;
