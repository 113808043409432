import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { CarListContext } from "../../context/MainProviders.js";
const CarList = ({ availableCarList }) => {
  const carList = useContext(CarListContext);
  const [activePage, setActivePage] = useState(1);
  const [prevPage, setPrevPage] = useState(0);
  const [classList, setClassList] = useState([]);
  const generateClassList = () => {
    let classListTmp = [];
    availableCarList.forEach((car) => {
      if (!validateCarClassExist(classListTmp, car.vehicle_class.name)) {
        classListTmp.push(car);
      }
    });
    setClassList(classListTmp);
  };
  const validateCarClassExist = (list, name) => {
    let found = false;
    list.forEach((carClass) => {
      if (carClass.vehicle_class.name === name) found = true;
    });
    return found;
  };
  const getPagination = () => {
    let pagination = [];
    let result = 0;
    if (carList.brandFilter === "All brands") {
      result = Math.ceil(
        classList.length / process.env.REACT_APP_CAR_LIST_PAGINATION_ELEMENTS
      );
    } else {
      result = Math.ceil(
        carList.brandList.length /
          process.env.REACT_APP_CAR_LIST_PAGINATION_ELEMENTS
      );
    }
    for (let index = 0; index < result; index++) {
      pagination.push(index);
    }
    return pagination;
  };
  const changePage = (page) => {
    setPrevPage(page - 1);
    setActivePage(page);
  };
  useEffect(() => {
    generateClassList();
  }, [availableCarList]);

  return (
    <>
      <ul className="p-0 list-none flex flex-wrap">
        {carList.brandFilter === "All brands"
          ? classList.length > 0
            ? classList.map((car, index) =>
                car.available /*&&
                index <=
                  process.env.REACT_APP_CAR_LIST_PAGINATION_ELEMENTS *
                    activePage && //validate element belongs current page
                index >
                  process.env.REACT_APP_CAR_LIST_PAGINATION_ELEMENTS *
                    prevPage*/ ? (
                  //validate if element is not in the prev pages
                  <li
                    key={car.id}
                    className="w-full md:w-5/12 p-3 border-2 border-neutral-200 my-4 mx-4"
                  >
                    <button>
                      {
                        <Link to={"/cars/" + car.id}>
                          <img
                            src={car.vehicle_class.public_image_link + "/"}
                            alt=""
                            className="w-full"
                          />
                        </Link>
                      }
                    </button>
                    <h3 className="text-center text-lg font-bold py-2 px-0">
                      <Link to={"/cars/" + car.id}>
                        {car.vehicle_class.name}
                      </Link>
                    </h3>
                    <ul className="actions mt-4 px-0 flex w-full">
                      <li className="w-full sm:w-2/4 text-white text-lg">
                        <Link
                          className="inline-block w-full text-center text-xs font-bold uppercase py-2 bg-black"
                          to={"/cars/" + car.id}
                        >
                          Rent Car
                        </Link>
                      </li>
                      <li className="w-full sm:w-2/4 text-white text-lg">
                        <Link
                          className="inline-block w-full text-center text-xs font-bold uppercase py-2 bg-red-600"
                          to={"/cars/" + car.id}
                        >
                          Details
                        </Link>
                      </li>
                    </ul>
                  </li>
                ) : null
              )
            : null
          : carList.brandList.map((car, index2) =>
              car.available ? (
                <li
                  key={car.id}
                  className="w-full md:w-5/12 p-3 border-2 border-neutral-200 my-4 mx-4"
                >
                  <Link to={"/cars/" + car.id}>
                    {
                      <img
                        src={car.vehicle_class.public_image_link + "/"}
                        alt=""
                        className="w-full"
                      />
                    }
                  </Link>
                  <h3 className="text-center text-lg font-bold py-2 px-0">
                    <Link to={"/cars/" + car.id}>{car.vehicle_class.name}</Link>
                  </h3>
                  <ul className="actions mt-4 px-0 flex w-full">
                    <li className="w-full sm:w-2/4 text-white text-lg">
                      <Link
                        className="inline-block w-full text-center text-xs font-bold uppercase py-2 bg-black"
                        to={"/cars/" + car.id}
                      >
                        Rent Car
                      </Link>
                    </li>
                    <li className="w-full sm:w-2/4 text-white text-lg">
                      <Link
                        className="inline-block w-full text-center text-xs font-bold uppercase py-2 bg-red-600"
                        to={"/cars/" + car.id}
                      >
                        Details
                      </Link>
                    </li>
                  </ul>
                </li>
              ) : null
            )}
      </ul>
      <ul
        ide="cars-list-pagination"
        className="flex w-11/12 sm:w-3/4 lg:2-2/5 xl:w-80 p-0 list-none mb-4"
      >
        {carList.brandFilter === "All brands" ? (
          <li className="text-xs py-2 mr-4 ml-4">
            Page {activePage} of{" "}
            {Math.ceil(
              classList.length /
                process.env.REACT_APP_CAR_LIST_PAGINATION_ELEMENTS
            )}
          </li>
        ) : (
          <li className="text-xs py-2 mr-4 ml-4">
            Page {activePage} of{" "}
            {Math.ceil(
              carList.brandList.length /
                process.env.REACT_APP_CAR_LIST_PAGINATION_ELEMENTS
            )}
          </li>
        )}

        {getPagination().map((page) => (
          <li className="mr-1" key={page}>
            <button
              onClick={() => changePage(page + 1)}
              className={
                page + 1 === activePage
                  ? "block text-sm font-bold py-1 px-2 rounded text-white bg-black hover:text-white hover:bg-black"
                  : "block text-sm font-bold py-1 px-2 rounded hover:text-white hover:bg-black"
              }
            >
              {page + 1}
            </button>
          </li>
        ))}
      </ul>
    </>
  );
};

export default CarList;
