import { useState, useContext } from "react";
import { GeneralContext } from "../context/MainProviders";
const Authentication = () => {
  const globalCtx = useContext(GeneralContext);
  const [showAuthError, setShowAuthError] = useState(false);
  const [user, setUser] = useState("");
  const [pass, setPass] = useState("");
  const authenticate = (e) => {
    e.preventDefault();
    setShowAuthError(false);
    if (user === "racecarrental" && pass === "]%{9JNt>:Eb=@DRL") {
      globalCtx.changeAuthenticated(true);
      window.location.replace(process.env.REACT_APP_DOMAIN);
    } else setShowAuthError(true);
  };
  //show the authentication page
  return (
    <div id="authentication">
      <form
        className="w-[330px] my-0 mx-auto mt-[20%] border border-neutral-200 p-4"
        onSubmit={(e) => authenticate(e)}
      >
        <h1>Simple Authentication</h1>
        {showAuthError && (
          <p className="bg-red-200 text-red-500 rounded-sm mb-2 p-1 text-sm">
            User and/or password are incorrect
          </p>
        )}
        <input
          className="w-full border border-neutral-300 mb-2 p-1"
          placeholder="Name"
          onChange={(e) => setUser(e.target.value)}
        />
        <input
          className="w-full border border-neutral-300 p-1"
          type="password"
          placeholder="password"
          onChange={(e) => setPass(e.target.value)}
        />
        <button className="bg-blue-300 mt-2 py-1 px-2">Send</button>
      </form>
    </div>
  );
};

export default Authentication;
