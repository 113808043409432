import { useState, useEffect, useContext } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import api from "../../../service/race_api";
import { FilterContext } from "../../../components/context/MainProviders.js";

const FilterCar = () => {
  const filter = useContext(FilterContext);
  const [locations, setLocations] = useState([]);
  useEffect(() => {
    //fill out the saved locations in local storage
    api.get(process.env.REACT_APP_API_FLEETS_LOCATIONS).then((res) => {
      setLocations(res.data.fleets_locations);
    });
  }, []);
  return (
    <div className="bg-neutral-300 p-4">
      <div className="form-field relative">
        {locations.length > 0 ? (
          <select
            className="w-full text-xs p-1 mb-2 focus:outline-none"
            onChange={(e) => filter.ChangeFrom(e.target.value)}
            value={filter.from}
          >
            <option value="">- Select from -</option>
            {locations.map((location) => (
              <option key={location.id} value={location.id}>
                {location.name}
              </option>
            ))}
          </select>
        ) : null}
      </div>
      <div className="form-field relative">
        {locations.length > 0 ? (
          <select
            className="w-full text-xs p-1 mb-2 focus:outline-none"
            onChange={(e) => filter.ChangeTo(e.target.value)}
            value={filter.to}
          >
            <option value="">- Select to -</option>
            {locations.map((location) => (
              <option key={location.id} value={location.id}>
                {location.name}
              </option>
            ))}
          </select>
        ) : null}
      </div>
      {/* <select className="w-full p-1 text-xs font-light">
        <option value="1">AC Car</option>
      </select> */}
      <DatePicker
        className="w-full text-xs p-1"
        selected={filter.datePickup}
        onChange={(date) => filter.ChangeDatePickup(date)}
        timeInputLabel="Time:"
        placeholderText="Start of journey date/time"
        dateFormat="dd/MM/yyyy h:mm aa"
        showTimeInput
        minDate={new Date()}
      />
      <DatePicker
        className="mt-2 w-full text-xs p-1"
        selected={filter.dateReturn}
        onChange={(date) => filter.ChangeDateReturn(date)}
        timeInputLabel="Time:"
        placeholderText="End of journey date/time"
        dateFormat="dd/MM/yyyy h:mm aa"
        showTimeInput
        startDate={filter.datePickup}
        minDate={filter.datePickup}
      />
      {/* <button className="uppercase text-red-600 text-sm border border-red-600 w-full mt-5 bg-white py-1">
        Find car
      </button> */}
    </div>
  );
};

export default FilterCar;
