import { useState, useEffect } from "react";
import api from "../../service/api";
import Helmet from "react-helmet";
import Breadcrumb from "../layout/blocks/Breadcrumb";
import Transitions from "../animations/Transitions";

const Services = () => {
  const [page, setPage] = useState([]);
  const [servicesList, setServicesList] = useState([]);
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const showService = (id) => {
    let service = document.getElementById("service-list-content-" + id);
    let serviceOpener = document.getElementById("service-list-opener-" + id);
    if (service.classList.contains("hidden")) {
      service.classList.remove("hidden");
      serviceOpener.classList.remove("before:content-['›']");
      serviceOpener.classList.add("before:content-['⌄']");
    } else {
      service.classList.add("hidden");
      serviceOpener.classList.remove("before:content-['⌄']");
      serviceOpener.classList.add("before:content-['›']");
    }
  };
  useEffect(() => {
    let checkout_block = document.getElementById("checkout-reservations-block");
    checkout_block.classList.add("hidden");
    api.get("/page/" + process.env.REACT_APP_PAGE_SERVICES_ID).then((res) => {
      setPage(res.data.data);
      //get meta
      const title = res.data.data.title;
      setMetaTitle(title);
      const description = res.data.data.description;
      setMetaDescription(description);
    });
    api.get("/services").then((res) => {
      setServicesList(res.data.data);
    });
  }, []);
  return (
    <>
      <Transitions>
        <Helmet>
          <link
            rel="canonical"
            href={process.env.REACT_APP_DOMAIN + "/about-us"}
          />
          <title>{process.env.REACT_APP_NAME + " | " + metaTitle}</title>
          <meta name="description" content={metaDescription} />
          <meta name="og:title" content={metaTitle} />
          <meta name="og:description" content={metaDescription} />
        </Helmet>
        <img
          rel="banner"
          src={process.env.REACT_APP_CMS_DOMAIN + "/" + page.banner}
        />
        <section className="content max-w-xl mx-auto overflow-hidden lg:max-w-4xl md:max-w-2xl xl:max-w-6xl py-4 px-2">
          <Breadcrumb />
          <h1 className="text-red-600 text-2xl text-center mb-4">
            <span className="text-black text-lg">Our</span> <br />
            Services
          </h1>
          <div
            className="page-content text-neutral-600 text-sm p-2"
            dangerouslySetInnerHTML={{ __html: page.content }}
          />
          <div id="services-list">
            {servicesList.map((service) => (
              <div
                key={service.id}
                className="mb-2 relative"
                id={"service-" + service.id}
              >
                <div
                  id={"service-list-opener-" + service.id}
                  className="before:block before:content-['›'] before:w-[20px] before:h-[20px] before:relative before:text-white before:text-2xl before:left-[20px] before:top-[8px] h-0 w-[7%] md:w-[7%] absolute left-0 top-0 border-b-[48px] border-b-red-600 border-r-[40px] border-r-transparent"
                ></div>
                <div
                  className="service-list-header cursor-pointer bg-black text-white pl-20 pt-[9.5px] pr-2 pb-[9.5px] text-[1.2rem]"
                  onClick={(e) => showService(service.id)}
                >
                  {service.title}
                </div>
                <div
                  id={"service-list-content-" + service.id}
                  className="service-list-content hidden text-neutral-600 text-sm p-2"
                  dangerouslySetInnerHTML={{ __html: service.content }}
                />
              </div>
            ))}
          </div>
        </section>
      </Transitions>
    </>
  );
};

export default Services;
