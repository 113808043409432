import { React, useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// layout
import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";
import Navigation from "./components/layout/Nav";

// Pages
import CheckoutReservations from "./components/pages/CheckoutReservations";
import Home from "./components/pages/Home";
import NeedHelp from "./components/pages/NeedHelp";
import AboutUs from "./components/pages/AboutUs";
import HowItWorks from "./components/pages/HowItWorks";
import RentACar from "./components/pages/RentACar";
import Cars from "./components/pages/Cars";
import Car from "./components/pages/Car";
import Billing from "./components/checkout/Billing";
import Review from "./components/checkout/Review";
import Placeorder from "./components/checkout/Placeorder";
import Response from "./components/checkout/Response";
import Services from "./components/pages/Services";
import News from "./components/pages/News";
import Contact from "./components/pages/Contact";
import Search from "./components/pages/Search";
import Article from "./components/pages/Article";
import TermsAndConditions from "./components/pages/TermsAndConditions";
import PrivacyPolicy from "./components/pages/PrivacyPolicy";
import PaymentAndBooking from "./components/pages/PaymentAndBooking";

import Authentication from "./components/pages/Authentication";
import GetInTouchPage from "./components/pages/GetInTouchPage";

//Providers
import {
  CartProvider,
  FilterProvider,
  AdditionalChargesProvider,
  CarListProvider,
  GeneralProvider,
} from "./components/context/MainProviders.js";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  useEffect(() => {
    if (localStorage.getItem("authenticated") !== null) {
      if (localStorage.getItem("authenticated")) {
        setIsAuthenticated(true);
      }
    }
  }, []);
  if (!isAuthenticated) {
    //show the get in touch page
    return (
      <Router>
        <GeneralProvider>
          <Routes>
            <Route path="/" element={<GetInTouchPage />} />
            <Route path="/auth" element={<Authentication />} />
          </Routes>
        </GeneralProvider>
      </Router>
    );
  }
  return (
    <Router>
      <CartProvider>
        <FilterProvider>
          <AdditionalChargesProvider>
            <CarListProvider>
              <Header />
              <Navigation />
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/need-help" element={<NeedHelp />} />
                <Route
                  path="/checkout-reservations"
                  element={<CheckoutReservations />}
                />
                <Route path="/about-us" element={<AboutUs />} />
                <Route path="/how-it-works" element={<HowItWorks />} />
                <Route path="/faq" element={<RentACar />} />
                <Route path="/cars" element={<Cars />} />
                <Route path="/cars/:carId" element={<Car />} />
                <Route path="/billing" element={<Billing />} />
                <Route path="/review" element={<Review />} />
                <Route path="/placeorder" element={<Placeorder />} />
                <Route path="/payment-response" element={<Response />} />
                <Route path="/services" element={<Services />} />
                <Route path="/news" element={<News />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/search" element={<Search />} />
                <Route path="/news/:articleId" element={<Article />} />
                <Route
                  path="/terms-and-conditions"
                  element={<TermsAndConditions />}
                />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route
                  path="/payment-and-booking"
                  element={<PaymentAndBooking />}
                />
              </Routes>
              <Footer />
            </CarListProvider>
          </AdditionalChargesProvider>
        </FilterProvider>
      </CartProvider>
    </Router>
  );
}

export default App;
