import { Link } from "react-router-dom";
import Clock from "../../../assets/images/clock.png";
import facebook from "../../../assets/images/facebook.png";
import instagram from "../../../assets/images/instagram.png";

const SocialSchedule = () => {
  return (
    <div className="social-schedule flex justify-center">
      <div className="schedule flex flex-row w-full sm:w-2/4 md:w-3/4 justify-end sm:justify-center mr-2">
        <span>
          <img src={Clock} alt="schedule" className="w-6 mt-1 md:mt-1 md:w-8" />
        </span>
        <div className="schedule-spec pl-4 text-gray-800 text-xs sm:text-base">
          <p className="text-sm md:text-base">Everyday</p>
          <p className="text-xs md:text-sm font-light schedule-time">
            7:00am to 11:00pm
          </p>
        </div>
      </div>
      <div className="social justify-end hidden sm:w-2/4 md:w-1/4 sm:flex">
        <ul className="flex justify-items-stretch">
          <li className="p-1">
            <a
              target="_blank"
              rel="noreferrer"
              href={process.env.REACT_APP_FACEBOOK_LINK}
            >
              <img src={facebook} alt="facebook" />
            </a>
          </li>
          <li className="p-1">
            <a
              target="_blank"
              rel="noreferrer"
              href={process.env.REACT_APP_INSTAGRAM_LINK}
            >
              <img src={instagram} alt="instagram" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SocialSchedule;
