import { useState, useEffect } from "react";
import api from "../../service/api";
import Helmet from "react-helmet";
import Breadcrumb from "../layout/blocks/Breadcrumb";
import Transitions from "../animations/Transitions";

const HowItWorks = () => {
  const [page, setPage] = useState([]);
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  useEffect(() => {
    let checkout_block = document.getElementById("checkout-reservations-block");
    checkout_block.classList.add("hidden");
    api
      .get("/page/" + process.env.REACT_APP_PAGE_HOW_IT_WORKS_ID)
      .then((res) => {
        setPage(res.data.data);
        //get meta
        const title = res.data.data.title;
        setMetaTitle(title);
        const description = res.data.data.description;
        setMetaDescription(description);
      });
  }, []);
  return (
    <>
      <Transitions>
        <Helmet>
          <link
            rel="canonical"
            href={process.env.REACT_APP_DOMAIN + "/how-it-works"}
          />
          <title>{process.env.REACT_APP_NAME + " | " + metaTitle}</title>
          <meta name="description" content={metaDescription} />
          <meta name="og:title" content={metaTitle} />
          <meta name="og:description" content={metaDescription} />
        </Helmet>
        <img
          rel="banner"
          src={process.env.REACT_APP_CMS_DOMAIN + "/" + page.banner}
        />
        <section className="content max-w-xl mx-auto overflow-hidden md:max-w-2xl lg:max-w-4xl xl:max-w-6xl py-4 px-2">
          <Breadcrumb />
          <h1 className="text-red-600 text-2xl text-center mb-4">
            <span className="text-black text-lg">How it</span> <br />
            Works
          </h1>
          <div
            className="page-content text-neutral-600 text-sm p-2"
            dangerouslySetInnerHTML={{ __html: page.content }}
          />
        </section>
      </Transitions>
    </>
  );
};

export default HowItWorks;
