import { useState, createContext } from "react";

export const GeneralContext = createContext({});
export const CartContext = createContext({});
export const FilterContext = createContext({});
export const AdditionalChargesContext = createContext({});
export const CarListContext = createContext({});

const GeneralProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(
    localStorage.getItem("authenticated") !== null
      ? localStorage.getItem("authenticated")
      : false
  );
  const changeAuthenticated = (value) => {
    setIsAuthenticated(value);
    localStorage.setItem("authenticated", value);
  };
  return (
    <>
      <GeneralContext.Provider
        value={{
          isAuthenticated,
          changeAuthenticated,
        }}
      >
        {children}
      </GeneralContext.Provider>
    </>
  );
};

const CartProvider = ({ children }) => {
  const [isEmpty, setIsEmpty] = useState(
    localStorage.getItem("cart") !== null
      ? JSON.parse(localStorage.getItem("cart")).isEmpty
      : true
  );
  const [acceptedTermsAndConditions, setAcceptedTermsAndConditions] = useState(
    localStorage.getItem("cart") !== null
      ? JSON.parse(localStorage.getItem("cart")).acceptedTermsAndConditions
      : false
  );
  const [step, setStep] = useState(
    localStorage.getItem("cart") !== null
      ? JSON.parse(localStorage.getItem("cart")).step
      : true
  );
  const [total, setTotal] = useState(
    localStorage.getItem("cart") !== null
      ? JSON.parse(localStorage.getItem("cart")).total
      : true
  );
  const [idReservation, setIdReservation] = useState(
    localStorage.getItem("cart") !== null
      ? JSON.parse(localStorage.getItem("cart")).id_reservation
      : null
  );

  const changeCartEmpty = (value) => {
    setIsEmpty(value);
    let cartObj = JSON.parse(localStorage.getItem("cart"));
    cartObj.isEmpty = value;
    localStorage.setItem("cart", JSON.stringify(cartObj));
  };

  const changeCartStep = (value) => {
    setStep(value);
    let cartObj = JSON.parse(localStorage.getItem("cart"));
    cartObj.step = value;
    localStorage.setItem("cart", JSON.stringify(cartObj));
  };

  const changeAcceptedTermsAndConditions = (value) => {
    setAcceptedTermsAndConditions(value);
    let cartObj = JSON.parse(localStorage.getItem("cart"));
    cartObj.acceptedTermsAndConditions = value;
    localStorage.setItem("cart", JSON.stringify(cartObj));
  };

  const changeTotal = (value) => {
    setTotal(value);
    let cartObj = JSON.parse(localStorage.getItem("cart"));
    cartObj.total = value;
    localStorage.setItem("cart", JSON.stringify(cartObj));
  };

  const changeIdReservation = (value) => {
    setIdReservation(value);
    let cartObj = JSON.parse(localStorage.getItem("cart"));
    cartObj.id_reservation = value;
    localStorage.setItem("cart", JSON.stringify(cartObj));
  };

  const clean = () => {
    setIsEmpty(true);
    setAcceptedTermsAndConditions(false);
    setStep("");
    setTotal(0);
    setIdReservation(null);
    init();
  };

  const init = () => {
    let cartObj = {
      isEmpty: true,
      acceptedTermsAndConditions: false,
      step: "billing",
      total: 0,
      id_reservation: null,
    };
    localStorage.setItem("cart", JSON.stringify(cartObj));
  };

  return (
    <>
      <CartContext.Provider
        value={{
          isEmpty,
          step,
          acceptedTermsAndConditions,
          total,
          idReservation,
          changeCartEmpty,
          changeCartStep,
          changeAcceptedTermsAndConditions,
          changeTotal,
          changeIdReservation,
          clean,
          init,
        }}
      >
        {children}
      </CartContext.Provider>
    </>
  );
};

const FilterProvider = ({ children }) => {
  const [from, setFrom] = useState(
    localStorage.getItem("filter") !== null
      ? JSON.parse(localStorage.getItem("filter")).from
      : ""
  );
  const [to, setTo] = useState(
    localStorage.getItem("filter") !== null
      ? JSON.parse(localStorage.getItem("filter")).to
      : ""
  );
  const [datePickup, setDatePickup] = useState(
    localStorage.getItem("filter") !== null
      ? new Date(JSON.parse(localStorage.getItem("filter")).datePickup)
      : new Date()
  );
  const [dateReturn, setDateReturn] = useState(
    localStorage.getItem("filter") !== null
      ? new Date(JSON.parse(localStorage.getItem("filter")).dateReturn)
      : new Date()
  );
  const [brandId, setBrandId] = useState(
    localStorage.getItem("filter") !== null
      ? JSON.parse(localStorage.getItem("filter")).brandId
      : 0
  );
  const [classId, setClassId] = useState(
    localStorage.getItem("filter") !== null
      ? JSON.parse(localStorage.getItem("filter")).classId
      : 0
  );

  const ChangeFrom = (value) => {
    setFrom(value);
    let filterObj = JSON.parse(localStorage.getItem("filter"));
    filterObj.from = value;
    localStorage.setItem("filter", JSON.stringify(filterObj));
  };

  const ChangeTo = (value) => {
    setTo(value);
    let filterObj = JSON.parse(localStorage.getItem("filter"));
    filterObj.to = value;
    localStorage.setItem("filter", JSON.stringify(filterObj));
  };

  const ChangeDatePickup = (value) => {
    setDatePickup(value);
    let filterObj = JSON.parse(localStorage.getItem("filter"));
    let m = value.getMonth() + 1;
    let d = value.getDate();
    let y = value.getFullYear();
    let h = value.getHours() < 10 ? "0" + value.getHours() : value.getHours();
    let mm =
      value.getMinutes() < 10 ? "0" + value.getMinutes() : value.getMinutes();
    let dateFormated = `${m}/${d}/${y} ${h}:${mm}:00`;
    filterObj.datePickup = dateFormated;
    localStorage.setItem("filter", JSON.stringify(filterObj));
  };

  const ChangeDateReturn = (value) => {
    setDateReturn(value);
    let filterObj = JSON.parse(localStorage.getItem("filter"));
    let m = value.getMonth() + 1;
    let d = value.getDate();
    let y = value.getFullYear();
    let h = value.getHours() < 10 ? "0" + value.getHours() : value.getHours();
    let mm =
      value.getMinutes() < 10 ? "0" + value.getMinutes() : value.getMinutes();
    let dateFormated = `${m}/${d}/${y} ${h}:${mm}:00`;
    filterObj.dateReturn = dateFormated;
    localStorage.setItem("filter", JSON.stringify(filterObj));
  };

  const ChangeBrandId = (value) => {
    setBrandId(value);
    let filterObj = JSON.parse(localStorage.getItem("filter"));
    filterObj.brandId = value;
    localStorage.setItem("filter", JSON.stringify(filterObj));
  };

  const ChangeClassId = (value) => {
    setClassId(value);
    let filterObj = JSON.parse(localStorage.getItem("filter"));
    filterObj.classId = value;
    localStorage.setItem("filter", JSON.stringify(filterObj));
  };

  const Clean = () => {
    setFrom("");
    setTo("");
    setDatePickup(new Date());
    setDateReturn(new Date());
    setBrandId(0);
    setClassId(0);
    Init();
  };

  const Init = () => {
    const today = new Date();
    let m = today.getMonth() + 1;
    let d = today.getDate();
    let y = today.getFullYear();
    let h = today.getHours();
    let mm = today.getMinutes();
    let todayFormated = `${m}/${d}/${y} ${h}:${mm}:00`;

    let filterObj = {
      from: "",
      to: "",
      datePickup: todayFormated,
      dateReturn: todayFormated,
      brandId: 0,
      classId: 0,
    };
    localStorage.setItem("filter", JSON.stringify(filterObj));
  };

  return (
    <>
      <FilterContext.Provider
        value={{
          from,
          to,
          datePickup,
          dateReturn,
          brandId,
          classId,
          ChangeFrom,
          ChangeTo,
          ChangeDatePickup,
          ChangeDateReturn,
          ChangeClassId,
          ChangeBrandId,
          Clean,
          Init,
        }}
      >
        {children}
      </FilterContext.Provider>
    </>
  );
};

const AdditionalChargesProvider = ({ children }) => {
  const [items, setItems] = useState(
    localStorage.getItem("additional_charges") !== null
      ? JSON.parse(localStorage.getItem("additional_charges")).items
      : []
  );
  const [selectedItems, setSelectedItems] = useState(
    localStorage.getItem("additional_charges") !== null
      ? JSON.parse(localStorage.getItem("additional_charges")).selectedItems
      : []
  );

  const updateItems = (value) => {
    setItems(value);
    let additionalChargesObj = JSON.parse(
      localStorage.getItem("additional_charges")
    );
    additionalChargesObj.items = value;
    localStorage.setItem(
      "additional_charges",
      JSON.stringify(additionalChargesObj)
    );
  };

  const updateSelectedItems = (value) => {
    setSelectedItems(value);
    let additionalChargesObj = JSON.parse(
      localStorage.getItem("additional_charges")
    );
    additionalChargesObj.selectedItems = value;
    localStorage.setItem(
      "additional_charges",
      JSON.stringify(additionalChargesObj)
    );
  };

  const clean = () => {
    setItems([]);
    setSelectedItems([]);
    init();
  };

  const init = () => {
    let additionalChargesObj = {
      items: [],
      selectedItems: [],
    };
    localStorage.setItem(
      "additional_charges",
      JSON.stringify(additionalChargesObj)
    );
  };

  return (
    <AdditionalChargesContext.Provider
      value={{
        items,
        selectedItems,
        updateItems,
        updateSelectedItems,
        clean,
        init,
      }}
    >
      {children}
    </AdditionalChargesContext.Provider>
  );
};

const CarListProvider = ({ children }) => {
  const [list, setList] = useState([]);
  const [brandFilter, setBrandFilter] = useState("All brands");
  const [brandList, setBrandList] = useState([]);
  const [classList, setClassList] = useState([]);

  const updateCarList = (value) => {
    setList(value);
  };

  const updateBrandFilter = (value) => {
    setBrandFilter(value);
  };

  const updateBrandList = (value) => {
    setBrandList(value);
  };

  const updateClassList = (value) => {
    setClassList(value);
  };

  const clean = () => {
    setList([]);
    setBrandList([]);
    setBrandFilter("All brands");
    setClassList([]);
  };

  return (
    <CarListContext.Provider
      value={{
        list,
        brandFilter,
        brandList,
        classList,
        updateCarList,
        updateBrandFilter,
        updateBrandList,
        updateClassList,
        clean,
      }}
    >
      {children}
    </CarListContext.Provider>
  );
};

export {
  GeneralProvider,
  CartProvider,
  FilterProvider,
  AdditionalChargesProvider,
  CarListProvider,
};
