import { useState, useEffect } from "react";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import api from "../../service/api";
import Transitions from "../animations/Transitions";
import Breadcrumb from "../layout/blocks/Breadcrumb";

const News = () => {
  const [articles, setArticles] = useState([]);
  const [pagination, setPagination] = useState([]);
  const [currentPage, setCurrentPage] = useState(null);
  const [page, setPage] = useState([]);
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");

  const ChangePage = (page) => {
    api.get("/articles?page=" + page).then((res) => {
      setArticles(res.data.data);
      printPagination(res.data.meta);
    });
  };
  const printPagination = (meta) => {
    let tmpPagination = [];
    for (let index = 1; index <= meta.last_page; index++) {
      if (meta.links[index].active === true) setCurrentPage(index);
      tmpPagination.push(index);
    }
    setPagination(tmpPagination);
  };
  useEffect(() => {
    let checkout_block = document.getElementById("checkout-reservations-block");
    checkout_block.classList.add("hidden");
    api.get("/articles").then((res) => {
      setArticles(res.data.data);
      printPagination(res.data.meta);
    });
    api.get("/page/" + process.env.REACT_APP_PAGE_ARTICLES_ID).then((res) => {
      setPage(res.data.data);
    });
  }, []);
  return (
    <>
      <Transitions>
        <Helmet>
          <link rel="canonical" href={process.env.REACT_APP_DOMAIN + "/news"} />
          <title>{process.env.REACT_APP_NAME + " | " + metaTitle}</title>
          <meta name="description" content={metaDescription} />
          <meta name="og:title" content={metaTitle} />
          <meta name="og:description" content={metaDescription} />
        </Helmet>
        <section className="py-0 px-2 content max-w-xl mx-auto overflow-hidden lg:max-w-4xl md:max-w-2xl xl:max-w-6xl">
          <Breadcrumb />
          <h1 className="text-red-600 text-2xl text-center mb-4">
            <span className="text-black text-lg">Some</span> <br />
            Latest News
          </h1>
          {articles.length > 0
            ? articles.map((article) => (
                <div
                  className={"article-" + article.id}
                  key={"article-" + article.id}
                >
                  <div className="inline-block w-full py-4 px-0 border-b-2 border-b-gray-400">
                    <h2 className="text-bold text-black text-2xl pb-4">
                      <Link to={"/news/" + article.id}>{article.title}</Link>
                    </h2>
                    <p>{article.description}</p>
                    <Link
                      to={"/news/" + article.id}
                      className="inline-block mt-2 text-red-600 border border-red-600 text-xs p-2 font-bold"
                    >
                      Read more
                    </Link>
                  </div>
                </div>
              ))
            : null}
          <div className="pagination my-0 mx-auto w-32 py-2 px-0">
            <ul className="flex">
              {pagination.length > 0
                ? pagination.map((page) => (
                    <li key={page} className="px-1 py-0">
                      <button
                        value={page}
                        onClick={(e) => ChangePage(e.target.value)}
                        className={
                          currentPage === page
                            ? "text-white bg-red-600 border-2 border-red-600 text-xs py-0 px-1 rounded-xl"
                            : "text-red-600 border-2 border-white bg-white text-xs py-0 px-1 rounded-xl"
                        }
                      >
                        {page}
                      </button>
                    </li>
                  ))
                : null}
            </ul>
          </div>
          <br />
          <br />
          <br />
          <div
            className="page-content text-neutral-600 text-sm p-2 flex flex-wrap"
            dangerouslySetInnerHTML={{ __html: page.content }}
          />
        </section>
      </Transitions>
    </>
  );
};

export default News;
