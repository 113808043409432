import { useContext, useState, useEffect } from "react";
import api from "../../../service/race_api";
import {
  CartContext,
  FilterContext,
} from "../../../components/context/MainProviders.js";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import moment from "moment-timezone";
import { motion } from "framer-motion";

const SearchBlock = () => {
  const [locations, setLocations] = useState([]);
  const filter = useContext(FilterContext);
  const cart = useContext(CartContext);
  useEffect(() => {
    //fill out the saved locations in local storage
    api.get(process.env.REACT_APP_API_FLEETS_LOCATIONS).then((res) => {
      setLocations(res.data.fleets_locations);
    });

    //init filter
    if (localStorage.getItem("filter") === null) {
      filter.Init();
    }

    //init cart
    if (localStorage.getItem("cart") === null) {
      cart.init();
    }
  }, []);

  moment.tz.setDefault(process.env.REACT_APP_TIMEZONE);

  return (
    <motion.div
      className="h-38 search-block w-full sm:w-[90%] sm:left-[5%] md:w-[80%] md:left-[10%] lg:w-[70%] lg:left-[15%] relative md:absolute flex flex-wrap shadow-lg z-10"
      animate={{ y: -70 }}
      transition={{ ease: "easeIn", duration: 2 }}
    >
      <h2 className="text-white bg-red-600 text-center font-bold text-2xl py-8 px-14 search-block-title w-full md:w-1/3">
        Search your car here
      </h2>
      <div className="search-form flex flex-wrap bg-white py-4 px-4 font-light w-full md:w-2/3">
        <div className="left w-1/2 md:w-1/3 pr-2">
          <div className="form-control my-4 mx-0">
            {locations !== "" ? (
              <select
                className="focus:outline-none border border-gray-500 w-full p-1 text-xs h-8"
                onChange={(e) => filter.ChangeFrom(e.target.value)}
                value={filter.from}
              >
                <option>- Select from -</option>
                {locations.map((location) => (
                  <option
                    key={"location-from-" + location.id}
                    value={location.id}
                  >
                    {location.name}
                  </option>
                ))}
              </select>
            ) : null}
          </div>
          <div className="form-control my-4 mx-0">
            <DatePicker
              className="mt-1 w-full text-xs p-[5px] border border-gray-500 focus:outline-none h-8"
              selected={filter.datePickup}
              onChange={(date) => filter.ChangeDatePickup(date)}
              timeInputLabel="Time:"
              placeholderText="Pickup date/time"
              dateFormat="dd/MM/yyyy h:mm aa"
              showTimeInput
              minDate={new Date()}
            />
          </div>
        </div>
        <div className="right w-1/2 md:w-1/3 pr-2">
          <div className="form-control my-4 mx-0">
            {locations !== "" ? (
              <select
                className="focus:outline-none border border-gray-500 w-full p-1 text-xs h-8"
                onChange={(e) => filter.ChangeTo(e.target.value)}
                value={filter.to}
              >
                <option>- Select to -</option>
                {locations.map((location) => (
                  <option
                    key={"location-to-" + location.id}
                    value={location.id}
                  >
                    {location.name}
                  </option>
                ))}
              </select>
            ) : null}
          </div>
          <div className="form-control my-4 mx-0">
            <DatePicker
              className="mt-1 w-full text-xs p-[5px] border border-gray-500 focus:outline-none h-8"
              selected={filter.dateReturn}
              onChange={(date) => filter.ChangeDateReturn(date)}
              timeInputLabel="Time:"
              placeholderText="Return date/time"
              dateFormat="dd/MM/yyyy h:mm aa"
              showTimeInput
              startDate={filter.datePickup}
              minDate={filter.datePickup}
            />
          </div>
        </div>
        <Link
          className="border-2 border-red-600 text-red-600 font-bold text-center w-full md:w-1/3 h-8 inline-block submit-button-bold my-4 mx-0"
          to="/cars"
        >
          Find car
        </Link>
      </div>
    </motion.div>
  );
};

export default SearchBlock;
