import { useEffect, useState } from "react";
import Helmet from "react-helmet";
import Transitions from "../animations/Transitions";
import Breadcrumb from "../layout/blocks/Breadcrumb";
const NeedHelp = () => {
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  useEffect(() => {
    let checkout_block = document.getElementById("checkout-reservations-block");
    checkout_block.classList.add("hidden");
  }, []);
  return (
    <>
      <Transitions>
        <Helmet>
          <link
            rel="canonical"
            href={process.env.REACT_APP_DOMAIN + "/need-help"}
          />
          <title>{process.env.REACT_APP_NAME + " | " + metaTitle}</title>
          <meta name="description" content={metaDescription} />
          <meta name="og:title" content={metaTitle} />
          <meta name="og:description" content={metaDescription} />
        </Helmet>
        <section className="content max-w-xl mx-auto overflow-hidden md:max-w-2xl xl:max-w-6xl">
          <Breadcrumb />
          <h1>Need Help?</h1>
        </section>
      </Transitions>
    </>
  );
};

export default NeedHelp;
