import { Link } from "react-router-dom";

const User = () => {
  return (
    <ul className="user flex justify-end">
      <li className="flex pr-2">
        <Link to="/checkout-reservations" className="inline-block px-1 py-1">
          Checkout your reservations
        </Link>
      </li>
    </ul>
  );
};

export default User;
