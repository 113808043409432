import useBreadcrumbs from "use-react-router-breadcrumbs";
import { NavLink } from "react-router-dom";
const Breadcrumb = ({ title }) => {
  const CustomBreadcrumb = ({ dynamicTitle }) => {
    return <span>{dynamicTitle}</span>;
  };
  const routes = [
    {
      path: "/news/:articleId",
      breadcrumb: CustomBreadcrumb,
      props: { dynamicTitle: title },
    },
    {
      path: "/cars/:carId",
      breadcrumb: CustomBreadcrumb,
      props: { dynamicTitle: title },
    },
  ];
  const breadcrumbs = useBreadcrumbs(routes);
  return (
    <div id="breadcrumb" className="flex flex-wrap list-none p-1">
      {breadcrumbs.map(({ match, breadcrumb }) => (
        <li
          key={match.pathname}
          className="after:content-['/'] after:p-1 last-of-type:after:content-[''] last-of-type:text-red-700"
        >
          <NavLink className="text-sm" to={match.pathname}>
            {breadcrumb}
          </NavLink>
        </li>
      ))}
    </div>
  );
};

export default Breadcrumb;
