import React, { useEffect } from "react";
import Helmet from "react-helmet";
import Breadcrumb from "../layout/blocks/Breadcrumb";

const CheckoutReservations = () => {
  useEffect(() => {
    let checkout_block = document.getElementById("checkout-reservations-block");
    checkout_block.classList.remove("hidden");
    checkout_block.classList.add("absoulte");
    checkout_block.classList.add("top-[35%]");
    checkout_block.classList.add("left-[20%]");
    checkout_block.classList.add("w-[1000px]");
    checkout_block.classList.add("h-[600px]");
  }, []);
  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href={process.env.REACT_APP_DOMAIN + "/about-us"}
        />
        <title>
          {process.env.REACT_APP_NAME + " | Checkout your reservations"}
        </title>
        <meta name="description" content={""} />
        <meta name="og:title" content={"Checkout your reservations"} />
        <meta name="og:description" content={""} />
      </Helmet>
      <section className="content max-w-xl mx-auto overflow-hidden lg:max-w-4xl md:max-w-2xl xl:max-w-6xl py-4 px-2">
        <Breadcrumb />
        <h1 className="text-red-600 text-2xl text-center mb-4">
          <span className="text-black text-lg">Checkout your</span> <br />
          Reservations
        </h1>
        <div id="checkout-reservations"></div>
      </section>
    </>
  );
};

export default CheckoutReservations;
