import { useState } from "react";

const Modal = (props) => {
  const [visible, setVisible] = useState(props.visible ? props.visible : false);
  const [styleError, setStyleError] = useState({
    title: "text-sm text-red-600 text-left text-lg mb-2",
    message: "text-xs text-red-600",
    box: "bg-[#ffea00] rounded-lg mt-4",
  });
  const [styleAlert, setStyleAlert] = useState({
    title: "text-sm text-[#e85d04] text-left text-lg mb-2",
    message: "text-xs text-[#e85d04]",
    box: "bg-[#ffea00] rounded-lg mt-4",
  });
  return (
    <div
      id="modal"
      className={
        !props.visible
          ? "hidden"
          : props.type === "alert"
          ? styleAlert.box + " w-full p-2"
          : styleError.box + " w-full p-2"
      }
    >
      <h3
        className={
          props.type === "error"
            ? styleError.title
            : props.type === "alert"
            ? styleAlert.title
            : ""
        }
      >
        {props.title}
      </h3>
      <p
        className={
          props.type === "error"
            ? styleError.message
            : props.type === "alert"
            ? styleAlert.message
            : ""
        }
      >
        {props.message}
      </p>
    </div>
  );
};

export default Modal;
