import { useState, useEffect } from "react";
import api from "../../service/api";
import Helmet from "react-helmet";
import Transitions from "../animations/Transitions";
import Breadcrumb from "../layout/blocks/Breadcrumb";

const PrivacyPolicy = () => {
  const [page, setPage] = useState([]);
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  useEffect(() => {
    let checkout_block = document.getElementById("checkout-reservations-block");
    checkout_block.classList.add("hidden");
    api
      .get("/page/" + process.env.REACT_APP_PAGE_PAYMENT_AND_BOOKING_ID)
      .then((res) => {
        setPage(res.data.data);
        //get meta
        const title = res.data.data.title;
        setMetaTitle(title);
        const description = res.data.data.description;
        setMetaDescription(description);
      });
  }, []);
  return (
    <>
      <Transitions>
        <Helmet>
          <link
            rel="canonical"
            href={process.env.REACT_APP_DOMAIN + "/payment-and-booking"}
          />
          <title>{process.env.REACT_APP_NAME + " | " + metaTitle}</title>
          <meta name="description" content={metaDescription} />
          <meta name="og:title" content={metaTitle} />
          <meta name="og:description" content={metaDescription} />
        </Helmet>
        <section className="py-4 px-2 content max-w-xl mx-auto overflow-hidden lg:max-w-4xl md:max-w-2xl xl:max-w-6xl">
          <Breadcrumb />
          <h1 className="text-red-600 text-2xl text-center mb-4">
            Payment & Booking
          </h1>
          <div
            className="page-content text-neutral-600 text-sm p-2"
            dangerouslySetInnerHTML={{ __html: page.content }}
          />
        </section>
      </Transitions>
    </>
  );
};

export default PrivacyPolicy;
