import { useContext, useEffect, useState } from "react";
import { CarListContext } from "../../context/MainProviders.js";
const FilterBrands = ({ availableCarList }) => {
  const carList = useContext(CarListContext);
  const [brandList, setBrandList] = useState([]);
  const [carClassesList, setCarClassesList] = useState([]);
  const getAllCarList = () => {
    let brands = [];
    let classList = generateClassList();
    setCarClassesList(classList);
    classList.forEach((item) => {
      if (item.vehicle_model !== null) {
        let brandName = item.vehicle_model.vehicle_brand.name;
        if (brands.find((brand) => brand.name === brandName)) {
          brands.forEach((item) => {
            if (item.name === brandName) {
              item.count += 1;
            }
          });
        } else {
          brands.push({ name: brandName, count: 1 });
        }
      }
    });
    setBrandList(brands);
  };
  const generateClassList = () => {
    let classListTmp = [];
    availableCarList.forEach((car) => {
      if (!validateCarClassExist(classListTmp, car.vehicle_class.name)) {
        classListTmp.push(car);
      }
    });
    return classListTmp;
  };
  const validateCarClassExist = (list, name) => {
    let found = false;
    list.forEach((carClass) => {
      if (carClass.vehicle_class.name === name) found = true;
    });
    return found;
  };
  const changeBrandFilter = (name) => {
    carList.updateBrandFilter(name);
    //generate brand car list
    let brandCarList = [];
    carClassesList.map((car) => {
      if (car.vehicle_model.vehicle_brand.name === name) {
        brandCarList.push(car);
      }
    });
    carList.updateBrandList(brandCarList);
  };
  useEffect(() => {
    getAllCarList();
  }, [availableCarList]);

  return (
    <ul id="filter-brands-list" className="bg-neutral-300 mt-4 p-4">
      <li
        className={
          carList.brandFilter === "All brands"
            ? "bg-white pl-4 pt-1 pb-1 pr-1 text-red-600 mb-1 text-xs cursor-pointer"
            : "bg-white pl-4 pt-1 pb-1 pr-1 text-black-600 hover:text-red-600 mb-1 text-xs cursor-pointer"
        }
        onClick={() => changeBrandFilter("All brands")}
      >
        All brands
      </li>
      {brandList.map((item, index) => (
        <li
          className={
            carList.brandFilter === item.name
              ? "bg-white pl-4 pt-1 pb-1 pr-1 text-red-600 text-xs mb-1 cursor-pointer"
              : "bg-white pl-4 pt-1 pb-1 pr-1 text-black hover:text-red-600 text-xs mb-1 cursor-pointer"
          }
          key={"brand-" + index}
          onClick={() => changeBrandFilter(item.name)}
        >
          {`${item.name}`}
        </li>
      ))}
    </ul>
  );
};

export default FilterBrands;
